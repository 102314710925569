import React, { FC } from 'react'
import { List } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/List'
import { SpecialOffer } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/SpecialOffer'
import { NewDishes } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/NewDishes'
import { LookAlso } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/LookAlso'
import { Selection } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Selection'
import { Map } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/Map'
import { GoThisWeek } from '@widgets/Restaurant/ResturantCards/RestCard/ui/CardTypes/GoThisWeek'
import { TypeAdvertisingCardRestVariety } from '@shared/api/analytics/advertising/advertising'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'

// list - Обычные списки
// special-offer - Спецпредложения
// new-dishes - Новые блюда
// look-also - Смотрите также
// selection - Подборки
// map - карточка на карте
// go-this-week - куда пойти на этой неделе

// Секция в котором расположена карточка.
// Если это промо-карточка то отправляется аналитика
export interface TypeAnalytic extends PickAnalytic<'section_name', 'section_index'> {
  advertCardPlaceType: TypeAdvertisingCardRestVariety | null
}

interface Props {
  type: 'list' | 'special-offer' | 'new-dishes' | 'look-also' | 'selection' | 'map' | 'go-this-week'
  data: TypeMappingRestOutput
  analytic: TypeAnalytic
  sizes?: string
  className?: string
  send_analytic?: boolean
}

const componentsByType = {
  list: List,
  'special-offer': SpecialOffer,
  'new-dishes': NewDishes,
  'look-also': LookAlso,
  selection: Selection,
  map: Map,
  'go-this-week': GoThisWeek,
}

const RestCard: FC<Props> = ({ type, data, analytic, sizes = 'L', className = '', send_analytic, ...props }) => {
  const Template = componentsByType[type]

  return (
    <Template
      analytic={analytic}
      data={data}
      sizes={sizes}
      className={className}
      send_analytic={send_analytic}
      {...props}
    />
  )
}

export default RestCard
