import l10n from './booking-localization'
import { TypeDay, TypeDays, TypeSchedules, TypeTime } from '@widgets/Restaurant/Booking/types'

const addZero = (number: number): string => `${`00${number}`.slice(-2)}`

// * --- Date --- *
// Получение расширенного объекта дня
const getNearDay = (daysGap = 0, restSchedule: TypeSchedules, preferredDay?: Date): TypeDay => {
  const thisDate = preferredDay || new Date()

  thisDate.setMinutes(Math.ceil(thisDate.getMinutes() / 30) * 30)
  thisDate.setDate(thisDate.getDate() + daysGap)

  const fullYear = thisDate.getFullYear()
  const monthStr = l10n('months_arr')[thisDate.getMonth()]

  const weekDayIndex = thisDate.getDay() === 0 ? 6 : thisDate.getDay() - 1
  const weekDayStr = l10n('weeks_abbr_arr')[weekDayIndex]

  const dayNumber = thisDate.getDate()
  const zeroMonth = addZero(thisDate.getMonth() + 1)
  const zeroDay = addZero(dayNumber)

  const outputSchedule = !restSchedule ? null : restSchedule[weekDayIndex]

  const openTime = !outputSchedule ? null : outputSchedule.open.split(':')
  const openRestDate = !openTime
    ? null
    : new Date(new Date(new Date(thisDate).setHours(Number(openTime[0]))).setMinutes(Number(openTime[1])))
  const isRestOpened = !restSchedule || restSchedule[weekDayIndex] ? 'opened' : 'closed'
  return {
    itemText: `${weekDayStr}, ${dayNumber} ${monthStr}`,
    itemTextShort: `${dayNumber} ${monthStr}`,
    schedule: outputSchedule,
    openRestDate: openRestDate ? openRestDate.toString() : null,
    value: `${fullYear}-${zeroMonth}-${zeroDay}`,
    thisDate: thisDate.toString(),
    isRestOpened: isRestOpened,
  }
}

// Создание расширенного массива из объектов о днях для бронирования
const generateDatesOptions = (restSchedule: TypeSchedules, preferredDay?: Date): TypeDays => {
  const optionsDaysCount = preferredDay ? 1 : 7
  const nearDays10template = Array.from(Array(optionsDaysCount).keys())

  const outputDates = Array.from(nearDays10template, (dayGapCount) =>
    getNearDay(dayGapCount, restSchedule, preferredDay),
  )

  return [...outputDates]
}

// * --- Time --- *

const getTimeObj = (timeGap = 0, selectedDateObj: TypeDay): TypeTime | null => {
  // define main data
  const todayUserDate = new Date()
  const isToday = new Date(selectedDateObj.thisDate).toLocaleDateString() === todayUserDate.toLocaleDateString()

  const selectedDate = new Date(selectedDateObj.thisDate)
  const thisDate = new Date(isToday ? selectedDateObj.thisDate : selectedDateObj.openRestDate)

  // update thisDate use gap
  const callcentreGap = isToday ? 30 : 0
  const thisGappedDate = new Date(thisDate)
  const gapMinutes = Math.ceil(thisGappedDate.getMinutes() / 30) * 30 + (timeGap - 1) * 30 + callcentreGap
  thisGappedDate.setMinutes(gapMinutes)
  // get gapped time slot
  const h24h = `0${thisGappedDate.getHours()}`.slice(-2)
  const m12h = addZero(thisGappedDate.getMinutes())
  const timeSlot = `${h24h}:${m12h}`

  // filter every slot by schedule
  const isDateBeforeMidnight = thisGappedDate.toLocaleDateString() === selectedDate.toLocaleDateString()

  const isOpenTime = selectedDateObj.schedule ? timeSlot >= selectedDateObj.schedule.open && isDateBeforeMidnight : null

  const isCloseTime = selectedDateObj.schedule
    ? timeSlot < selectedDateObj.schedule.close && !isDateBeforeMidnight
    : null

  const isFilteredTimeBySchedule = isOpenTime || isCloseTime

  // create output object
  const outputObj =
    !selectedDateObj.schedule || isFilteredTimeBySchedule
      ? {
          itemText: `${timeSlot}`,
          value: `${timeSlot}:00`,
          thisDate: thisGappedDate.toLocaleDateString(),
        }
      : null

  return outputObj
}

const generateTimeOptions = (selectedDayOption: TypeDay): (TypeTime | null)[] => {
  const OPTIONS_COUNT_TIMES = 48

  const nearTimes48template = Array.from(Array(OPTIONS_COUNT_TIMES + 1).keys()).slice(1)

  let generatedTimeArray = Array.from(nearTimes48template, (timeGapCount) =>
    getTimeObj(timeGapCount, selectedDayOption),
  )
  generatedTimeArray = generatedTimeArray.filter((thisTime) => !!thisTime)

  return [...generatedTimeArray]
}

export { generateDatesOptions, generateTimeOptions, addZero }
