import React, { FC } from 'react'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import s from '@features/PromoRoll/PromoRoll.module.sass'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { PromoCardSkeleton } from '@entities/PromoCard'

const Skeleton: FC = () => {
  const arrSkeleton = Array.from(Array(3).keys())

  return (
    <ErrorBoundary>
      <div className={s.promo}>
        <Carousel>
          <Carousel.Slider shadow={true}>
            {arrSkeleton.map((thisPromoItem, idx) => (
              <PromoCardSkeleton key={idx} />
            ))}
          </Carousel.Slider>
        </Carousel>
      </div>
    </ErrorBoundary>
  )
}

export default Skeleton
