import { useMemo } from 'react'

/**
 * @function generateScheduleWeek
 *    Возвращает объект с 7 элементами
 *    Ключи - дни недели
 *    Значение - расписание работы в указанный день
 */
const generateScheduleWeek = (workingHours) => {
  const weekDayTemplate = ['понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота', 'воскресенье']
  const result = {}

  weekDayTemplate.forEach((thisDay, index) => {
    const workDay = workingHours.find((el) => el.day === thisDay)

    if (workDay) {
      result[index] = {
        open: workDay.time_start?.slice(0, 5) || null,
        // null может прийти с бека как обозначение о работе расторана до последнего клиента
        // в этом случае работа ресторана условно заканчивается в 22:00
        close: workDay.time_end ? workDay.time_end.slice(0, 5) : '22:00',
      }
    } else {
      result[index] = {
        open: null,
        close: null,
      }
    }
  })

  return result
}

/**
 * @function generateScheduleWorkDays
 *    Возвращает объект с расписанием работы в дни работы ресторана
 *    Ключи - дни недели
 *    Значение - расписание работы в указанный день
 */
const generateScheduleWorkDays = (workingHours) => {
  const result = {}
  const week = generateScheduleWeek(workingHours)
  Object.keys(week).filter((key) => {
    if (week[key].open !== null) result[key] = { ...week[key] }
  })
  return result
}

/**
 * @function generateScheduleWorkDays
 *    Возвращает объект с расписанием работы ресторана в текущий день недели пользователя
 */
const generateScheduleToday = (workingHours) => {
  return generateScheduleWeek(workingHours)[new Date(Date.now()).getDay() - 1]
}

/**
 * @function getShortTextSchedule
 *    Возвращает строку о статусе работы ресторана на данный момент
 *    Например: 'Открыто c 10:00 до 20:00', 'Откроется завтра в 12:00', 'Откроется в среду в 14:00'
 */
const getShortTextSchedule = (schedule_week) => {
  const weekDayNames = ['понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота', 'воскресенье']

  const currentIndexDay = new Date(Date.now()).getDay() - 1
  const isWorkToday = !!schedule_week[currentIndexDay]?.open
  if (isWorkToday) return `Открыто c ${schedule_week[currentIndexDay].open} до ${schedule_week[currentIndexDay].close}`

  let resultText = null

  const checkDayWork = ([indexDay, object]) => {
    if (object.open && !resultText) {
      if (Number(indexDay) === currentIndexDay + 1) {
        resultText = `Откроется завтра в ${object.open}`
      } else {
        const dayText = [2, 4, 5].includes(Number(indexDay))
          ? weekDayNames[indexDay].slice(0, -1) + 'у'
          : weekDayNames[indexDay]
        resultText = `Откроется ${Number(indexDay) === 1 ? 'во' : 'в'} ${dayText} в ${object.open}`
      }
    }
  }

  // конец текущей недели
  const endCurrentWeek = Object.entries(schedule_week).splice(currentIndexDay, 6)

  // начало следующей недели
  const startCurrentWeek = Object.entries(schedule_week).splice(0, currentIndexDay)

  endCurrentWeek.forEach(checkDayWork)
  startCurrentWeek.forEach(checkDayWork)

  return resultText
}

export { generateScheduleWeek, generateScheduleWorkDays, generateScheduleToday, getShortTextSchedule }
