import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'

import { TypeMappingFavoritesGQLOutput } from '@shared/api/middleware/mappingAPI/gql_favorites/type'

export const mappingFavoritesGQL = (object: any): TypeMappingFavoritesGQLOutput => {
  const result = {
    restaurants: {},
    news: {},
    selections: {},
  }
  try {
    const favorites = object.data.user.activity.favorites.items

    favorites.forEach((item) => {
      const isRest = item.subject.id.toLowerCase().includes('restaurant')
      if (isRest) result.restaurants[parseInt(item.subject.id.match(/\d+/))] = item.subject.name || true
    })

    return undefinedObjectToNull(result)
  } catch (error) {
    console.error(error)
    return result
  }
}
