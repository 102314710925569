import s from './BookingDateTime.module.sass'
import React, { FC } from 'react'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { BookingCalendar } from '@widgets/Restaurant/Booking/ui/components/BookingCalendar'
import { TypeDay } from '@widgets/Restaurant/Booking/types'

interface SectionDateProps {
  dateField: any
  handleChangeDateCalendar: any
  handleSetDate: any
  isDateErrorVisible: boolean
  schedule: any
  selectedDateTarget: any
  todayUserDate: any
  remarked_booking_is_active: any
}

/**
 * @function SectionDate - stateless presentational component for booking Date selection
 * @todo 86400000 - магическое число
 */
const SectionDate: FC<SectionDateProps> = ({
  dateField,
  handleChangeDateCalendar,
  handleSetDate,
  isDateErrorVisible,
  schedule,
  selectedDateTarget,
  todayUserDate,
  remarked_booking_is_active,
}) => {
  const getDateButtonsClassName = (dateObj: TypeDay): string => {
    const selected = dateField.selected?.value === dateObj.value && selectedDateTarget === 'buttons_target'
    let nameTemplate = `${s.button} ${s['button--date']} ${selected ? s['button--selected'] : ''}`
    if (dateObj.isRestOpened === 'closed') {
      nameTemplate += s['button--disabled']
    }
    return nameTemplate
  }

  return (
    <>
      <div className={`${s.block} ${s['block-date']}`}>
        <label htmlFor='select_booking-data' className={s['booking-label']}>
          {l10n('booking_date_label')}
        </label>

        <div className={s.content}>
          {dateField.options
            .filter(
              (thisDateObj: TypeDay) =>
                thisDateObj.thisDate &&
                (new Date(thisDateObj.thisDate).toLocaleDateString() === todayUserDate.toLocaleDateString() ||
                  new Date(thisDateObj.thisDate).toLocaleDateString() ===
                    new Date(todayUserDate.getTime() + 86400000).toLocaleDateString()),
            )
            .map((dateObj: TypeDay, i: number) => (
              <button
                onClick={() => {
                  dateObj.isRestOpened === 'opened' && handleSetDate(dateObj, 'target_id_' + i)
                }}
                className={getDateButtonsClassName(dateObj)}
                key={i}
                type='button'>
                {new Date(dateObj.thisDate).toLocaleDateString() === todayUserDate.toLocaleDateString() &&
                  l10n('booking_date_today')}
                {new Date(dateObj.thisDate).toLocaleDateString() ===
                  new Date(todayUserDate.getTime() + 86400000).toLocaleDateString() && l10n('booking_date_tomorrow')}
              </button>
            ))}

          <BookingCalendar
            selectedDate={dateField.selected}
            calendarTarget={selectedDateTarget === 'calendar_target'}
            schedule={schedule}
            datesOptions={dateField.options}
            handleChangeDate={handleChangeDateCalendar}
          />
        </div>

        {isDateErrorVisible && <span className={s.error}>{l10n('booking_date_validation')}</span>}
      </div>
    </>
  )
}

export default SectionDate
