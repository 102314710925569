import { sendClickHouseAnalytic, sendYandexMetricAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { globalStore } from '@app/model/store'

const getRestaurantData = (): PickAnalytic<'restaurant_id', 'restaurant_name'> => {
  const { name, id } = globalStore.getState().booking.restaurantSource

  return {
    restaurant_name: name,
    restaurant_id: id,
  }
}

// События бронирования
export const EGGBooking = {
  // START ЭКРАН БРОНИРОВАНИЯ СТОЛИКА

  // 1. Загрузился экран с выбором места
  appear_screen_reserve: () => {
    sendClickHouseAnalytic({
      event_name: 'screen_appear',
      page_name: 'reserve',
      ...getRestaurantData(),
    })

    sendYandexMetricAnalytic({ id: 27079337, type: 'reachGoal', event: 'open-reservation' })
    sendYandexMetricAnalytic({ id: 27508533, type: 'reachGoal', event: 'open-reservation' })
  },

  // 2. Клик на добавить "Количество гостей" (клик на плюс)
  click_reserve_add_guest: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'add_guest',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 3. Клик на удалить "Количество гостей" (клик на минус)
  click_reserve_remove_guest: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'remove_guest',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 4.1 Клик по кнопке Сегодня
  click_reserve_date_today: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'date_today',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 4.2 Клик по кнопке Завтра
  click_reserve_date_tomorrow: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'date_tomorrow',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 5. Клик на поле "Дата", выбор  из выпадающего списка (отправлять также при клике на кнопку Завтра/Сегодня)
  change_reserve_date: (props: PickAnalytic<'input'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'date',
      page_name: 'reserve',
      ...getRestaurantData(),
      ...props,
    }),

  // 6. Клик на поле "Время", выбор из списка
  click_reserve_time: (props: PickAnalytic<'input'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'time',
      page_name: 'reserve',
      ...getRestaurantData(),
      ...props,
    }),

  // 7.1. Клик на поле "На кого забронировать"/"ФИО"
  click_reserve_field_name: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'users_name',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 7.2. Ввод текста в поле "На кого забронировать"/"ФИО"
  input_reserve_field_name: () =>
    sendClickHouseAnalytic({
      event_name: 'type_text',
      element_type: 'users_name',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 8. Клик на поле "Телефон"
  click_reserve_field_phone: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'users_phone',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 9. Ввод текста в поле "Телефон"
  input_reserve_field_phone: () =>
    sendClickHouseAnalytic({
      event_name: 'type_text',
      element_type: 'users_phone',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 10. Клик на поле "Пожелания по бронированию"
  click_reserve_field_comment: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'users_comm',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 11. Ввод в поле "Пожелания по бронированию"
  input_reserve_field_comment: () =>
    sendClickHouseAnalytic({
      event_name: 'type_text',
      element_type: 'users_comm',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 12. Клик на "Закрыть"
  click_reserve_close: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'close',
      page_name: 'reserve',
      ...getRestaurantData(),
    }),

  // 12. Клик на кнопку "Забронировать столик"
  click_reserve_book: (props: PickAnalytic<'count_guest' | 'phone' | 'date' | 'time'>) => {
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'reserve',
      page_name: 'reserve',
      ...getRestaurantData(),
      ...props,
    })

    sendYandexMetricAnalytic({ id: 27079337, type: 'reachGoal', event: 'reserve-table-button' })
    sendYandexMetricAnalytic({ id: 27508533, type: 'reachGoal', event: 'reserve-table-button' })
  },

  // END ЭКРАН БРОНИРОВАНИЯ СТОЛИКА

  // START ЭКРАН УСПЕШНОГО БРОНИРОВАНИЯ
  // 1. Загрузился экран успешного бронирования
  appear_screen_success: (props: PickAnalytic<'count_guest' | 'phone' | 'date' | 'time'>) => {
    sendClickHouseAnalytic({
      event_name: 'screen_appear',
      page_name: 'success',
      ...getRestaurantData(),
      ...props,
    })

    sendYandexMetricAnalytic({ id: 27079337, type: 'reachGoal', event: 'success-reservation' })
    sendYandexMetricAnalytic({ id: 27508533, type: 'reachGoal', event: 'success-reservation' })
  },

  // 2. Клик на кнопку "Отлично"
  click_success_ok: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      page_name: 'success',
      element_type: 'ok',
      ...getRestaurantData(),
    }),

  // 3. Клик на "Закрыть"
  click_success_close: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      page_name: 'success',
      element_type: 'close',
      ...getRestaurantData(),
    }),

  // 4. Клик на поле "E-mail"
  click_success_email: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      page_name: 'success',
      element_type: 'email',
      ...getRestaurantData(),
    }),

  // 5. Начало ввода номера  телефона
  input_success_email: () =>
    sendClickHouseAnalytic({
      event_name: 'type_text',
      page_name: 'success',
      element_type: 'email',
      ...getRestaurantData(),
    }),

  // 6. Клик на кнопку Прислать на почту
  click_success_send_email: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      page_name: 'success',
      element_type: 'send',
      ...getRestaurantData(),
    }),
}
