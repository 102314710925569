import s from './BookingDateTime.module.sass'
import React, { FC } from 'react'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
// @ts-ignore
import IconDots from '@icons/icon-dots.svg'
import { TypeTime } from '@widgets/Restaurant/Booking/types'

interface SectionTimeProps {
  handleSetTime: any
  isAllTimeVisible: any
  isDesktop: boolean
  isMobile: boolean
  isShowSlotsLabels: boolean
  isTimeErrorVisible: boolean
  onlineSlotsLoading: any
  selectedTimeTarget: any
  setIsAllTimeVisible: any
  timeField: any
  timeSlots: any
}

/**
 * @function SectionTime - stateless presentational component for booking Time selection
 */
const SectionTime: FC<SectionTimeProps> = ({
  handleSetTime,
  isAllTimeVisible,
  isDesktop,
  isMobile,
  isShowSlotsLabels,
  isTimeErrorVisible,
  onlineSlotsLoading,
  selectedTimeTarget,
  setIsAllTimeVisible,
  timeField,
  timeSlots,
}) => {
  const placeholderCount = !isAllTimeVisible ? (isMobile ? 4 : 6) : isMobile ? 10 : 20

  const getClassButtonTime = (timeObj: TypeTime | null): string => {
    const selected = timeField.selected?.value === timeObj?.value && selectedTimeTarget === 'buttons_target'
    return `${s.button} ${s['button--time']} ${selected ? s['button--selected'] : ''}`
  }

  const getTimeShowAllClassName = (): string => {
    let outputClassName = `${s.button} ${s['button__show-all-time']}`
    if (isDesktop) {
      outputClassName += ` ${s['booking-button-dots-icon--wide']}`
    } else {
      outputClassName += ` ${s['booking-form-button--circle']}`
    }
    return outputClassName
  }

  return (
    <>
      <div className={`${s.block} ${s['block-time']}`}>
        {!!isShowSlotsLabels && (
          <label htmlFor='select_booking-time' className={s['booking-label']}>
            {l10n('booking_time_label')}
          </label>
        )}

        <div className={s.content}>
          {!onlineSlotsLoading && timeSlots?.length > 0 ? (
            <>
              {timeSlots
                .filter((thisTimeObj: TypeTime | null, i: number) =>
                  !isAllTimeVisible
                    ? isMobile
                      ? thisTimeObj?.thisDate && (i === 0 || i === 1 || i === 2)
                      : thisTimeObj?.thisDate && (i === 0 || i === 1 || i === 2 || i === 3 || i === 4)
                    : thisTimeObj?.thisDate,
                )
                .map((thisTimeObj: TypeTime | null, i: number) => (
                  <button
                    onClick={() => {
                      thisTimeObj?.value && handleSetTime(thisTimeObj.value)
                    }}
                    className={getClassButtonTime(thisTimeObj)}
                    key={i}
                    type='button'>
                    {thisTimeObj?.itemText}
                  </button>
                ))}

              {!isAllTimeVisible && (
                <button className={getTimeShowAllClassName()} type='button' onClick={() => setIsAllTimeVisible(true)}>
                  <IconDots />
                </button>
              )}
            </>
          ) : (
            <>
              {onlineSlotsLoading ? (
                Array.from({ length: placeholderCount }, (_, index) => (
                  <button
                    className={`${s['button']} ${s['button--placeholder']}`}
                    key={index + 1}
                    type='button'
                    disabled={true}
                  />
                ))
              ) : (
                <span className={s['no-slots-message']}>
                  На&nbsp;выбранную дату нет свободных столиков, попробуйте выбрать другую
                </span>
              )}
            </>
          )}
        </div>

        {isTimeErrorVisible && isShowSlotsLabels && <span className={s.error}>{l10n('booking_time_validation')}</span>}
      </div>
    </>
  )
}

export default SectionTime
