import ReactCrop, { centerCrop, convertToPixelCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import React, { FC, useEffect, useRef, useState } from 'react'
import s from './ImageCroppingPopup.module.sass'

import { useDebounceEffect } from '@widgets/Common/ImageCropping/helpers/useDebounceEffect'
import { canvasPreview } from '@widgets/Common/ImageCropping/helpers/canvasPreview'

import { IconButton } from '@shared/ui/Actions/IconButton'
import { Button } from '@shared/ui/Actions/Button'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Text } from '@shared/ui/Typography/Text'

// @ts-ignore
import IconMinus from '@icons/icon-cropper-minus.svg'
// @ts-ignore
import IconPlus from '@icons/icon-cropper-plus.svg'

interface Props {
  aspect: number // 16 / 9
  image?: string
  handleCancel: () => void
  handleSave: (src: string) => void
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ImageCroppingPopup: FC<Props> = ({ image = null, handleCancel, handleSave, aspect: aspectProps = 1 / 1 }) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 12,
    y: 12,
    width: 70,
    height: 70,
  })
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [aspect, setAspect] = useState<number | undefined>(aspectProps)

  const onImageLoad = () => {
    if (aspect) {
      setCrop({
        unit: '%', // Can be 'px' or '%'
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      })
    }
  }

  useEffect(() => {
    if (imgRef.current) {
      const { width, height } = imgRef.current
      const newCrop = centerAspectCrop(width, height, aspect)
      setCrop(newCrop)
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    }
  }, [imgRef.current])

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, 0)
      }
    },
    100,
    [completedCrop, scale],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else {
      setAspect(16 / 9)

      if (imgRef.current) {
        const { width, height } = imgRef.current
        const newCrop = centerAspectCrop(width, height, 16 / 9)
        setCrop(newCrop)
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height))
      }
    }
  }

  const handleClose = () => {
    handleCancel()
  }

  const upScale = () => {
    setScale(Math.min(1.5, Math.max(1, Number(scale + 0.05))))
  }
  const downScale = () => {
    setScale(Math.min(1.5, Math.max(1, Number(scale - 0.05))))
  }

  const handleSaveInner = async () => {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    // @ts-ignore
    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    // @ts-ignore
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    handleSave(blobUrlRef.current)
  }

  return (
    <Modal title={'Кадрирование изображения'} size={'M'} handleClose={handleClose} data-testid={'image-cropping-popup'}>
      <div className={s.wrapper}>
        <div className={s['wrapper-crop']}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            className={s.cropper}
            minHeight={100}>
            <div className={s['background-blur']} style={{ backgroundImage: `url(${image})` }}></div>
            <img
              ref={imgRef}
              alt='Crop me'
              src={image}
              className={s['image-crop']}
              style={{ transform: `scale(${scale})` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>

        {/* canvas необходим для работы. Эти блоки можно скрыть через hidden */}
        {!!completedCrop && (
          <div hidden>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />

            <button onClick={handleToggleAspectClick}>Toggle aspect {aspect ? 'off' : 'on'}</button>
          </div>
        )}

        <Text sizes={'S'} className={s.description}>
          Двигайте картинку и изменяйте ее размеры, чтобы выбрать область для сохранения
        </Text>

        <div className={s.footer}>
          <div className={s.scale}>
            <IconButton
              id={s['scale-icon']}
              sizes={'L'}
              mode={'white-border'}
              icon={IconMinus}
              onClick={downScale}
              disabled={scale === 1}
            />
            <Text sizes={'ML'}>{Number(scale * 100).toFixed(0)} %</Text>
            <IconButton
              id={s['scale-icon']}
              sizes={'L'}
              mode={'white-border'}
              icon={IconPlus}
              onClick={upScale}
              disabled={scale === 1.5}
            />
          </div>
          <Button sizes={'L'} mode={'black'} onClick={handleSaveInner} className={s.save}>
            Сохранить
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ImageCroppingPopup
