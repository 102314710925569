import { sha256 } from 'crypto-hash'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { ApolloClient, createHttpLink, gql, InMemoryCache } from '@apollo/client'

import { TypeMappingDailyGQLOutput } from '@shared/api/middleware/mappingAPI/gql_daily/type'
import { mappingAPI_GQL } from '@shared/api/middleware/mappingAPI'
import { cityIDContext } from '@shared/api/gql-service/city-Id-context'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_STAND = GITLAB_STATUS === '1'

const GRAPHQL_AFISHA_PROD = 'https://graph.afisha.ru/graphql/'
const GRAPHQL_AFISHA_STAGE = 'https://graph-dev.afisha.ru/graphql/'
const GQL_AFISHA_URI = GRAPHQL_AFISHA_PROD
// const GQL_AFISHA_URI = IS_PROD ? GRAPHQL_AFISHA_PROD : GRAPHQL_AFISHA_STAGE

const GRAPHQL_DAILY_PROD = 'https://daily.afisha.ru/public/api/graphql/'
const GRAPHQL_DAILY_STAGE = 'https://react-stage.daily.afisha.ru/public/api/graphql/'
const GQL_DAILY_URI = !IS_STAND ? GRAPHQL_DAILY_PROD : GRAPHQL_DAILY_STAGE

export const clientDaily = (hardcodedSha256 = null) => {
  const thisSha256 = hardcodedSha256 || sha256

  const thisPersistedQueryLink = createPersistedQueryLink({
    sha256: thisSha256,
    useGETForHashedQueries: true,
  })

  const thisCreateHttpLink = createHttpLink({ uri: GQL_DAILY_URI, credentials: 'include' })
  const thisQueryLink = cityIDContext.concat(thisPersistedQueryLink).concat(thisCreateHttpLink)
  const thisQueryCache = new InMemoryCache()

  const outputClient = new ApolloClient({
    name: 'daily_web',
    uri: GQL_DAILY_URI,
    link: thisQueryLink,
    cache: thisQueryCache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
  return outputClient
}

export const clientAfisha = (hardcodedSha256 = null) => {
  const thisSha256 = hardcodedSha256 || sha256
  const thisPersistedQueryLink = createPersistedQueryLink({
    sha256: thisSha256,
    useGETForHashedQueries: true,
  })

  const thisCreateHttpLink = createHttpLink({ uri: GQL_AFISHA_URI, credentials: 'include' })
  const thisQueryLink = cityIDContext.concat(thisPersistedQueryLink).concat(thisCreateHttpLink)
  const thisQueryCache = new InMemoryCache()

  const outputClient = new ApolloClient({
    name: 'web',
    uri: GQL_AFISHA_URI,
    link: thisQueryLink,
    cache: thisQueryCache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
  return outputClient
}

const GQL_TAGS = {
  favorites: gql`
    query Favorites($id: ID!, $first: Int!) {
      user(id: $id) {
        activity {
          favorites(first: $first) {
            totalCount

            items {
              subject {
                ... on Place {
                  __typename
                  id
                  name
                }

                ... on Concert {
                  __typename
                  id
                  name
                }

                ... on Person {
                  __typename
                  id
                  name
                }

                ... on Article {
                  __typename
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
  addFavorite: gql`
    mutation AddFavorite($input: AddFavoriteInput!) {
      addFavorite(input: $input) {
        clientMutationId
      }
    }
  `,
  deleteFavorite: gql`
    mutation DeleteFavorite($input: DeleteFavoriteInput!) {
      deleteFavorite(input: $input) {
        clientMutationId
      }
    }
  `,

  daily: gql`
    query GetDaily {
      recentArticles(filter: { rubric: ["Еда"], theme: ["Рестораны"], limit: 20 }) {
        ... on Entry {
          id
          title
          slug
          photoModifications {
            crop {
              size {
                height
                width
              }
            }
            photoPath
            place
          }
          rubric {
            slug
          }
        }
      }
    }
  `,
}

export const API_GRAPH = {
  favorites: {
    qgl: GQL_TAGS.favorites,
    load: (variables: { id: string; first: number }) => {
      return mappingAPI_GQL.favorites(clientAfisha().query({ query: GQL_TAGS.favorites, variables }))
    },
  },
  addFavorite: {
    qgl: GQL_TAGS.addFavorite,
    load: (variables: { subjectId: number; subjectTypeName: 'Restaurant' }) => {
      const arg = {
        input: {
          subjectId: `${variables.subjectTypeName}_${variables.subjectId}`,
          clientMutationId: 'AfishaRestaurantsForever',
        },
      }
      return mappingAPI_GQL.addFavorite(clientAfisha().mutate({ mutation: GQL_TAGS.addFavorite, variables: arg }))
    },
  },
  deleteFavorite: {
    qgl: GQL_TAGS.deleteFavorite,
    load: (variables: { subjectId: number; subjectTypeName: 'Restaurant' }) => {
      const arg = {
        input: {
          subjectId: `${variables.subjectTypeName}_${variables.subjectId}`,
          clientMutationId: 'AfishaRestaurantsForever',
        },
      }
      return mappingAPI_GQL.deleteFavorite(clientAfisha().mutate({ mutation: GQL_TAGS.deleteFavorite, variables: arg }))
    },
  },

  daily: {
    qgl: GQL_TAGS.daily,
    load: (): Promise<TypeMappingDailyGQLOutput[]> => {
      const GQL_QUERY_DAILY_HARDCODE = () => '2f8b75c9c21abef58d02f3a117391729cef91652c8f38151ab5bf60543b7551e'
      // or check https://www.apollographql.com/docs/react/api/link/apollo-link-http / Dynamic URI
      return mappingAPI_GQL.daily(clientDaily(GQL_QUERY_DAILY_HARDCODE).query({ query: GQL_TAGS.daily }))
    },
  },
}
