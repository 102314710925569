/* eslint-disable no-unused-vars */

import s from './Gallery.module.sass'

import React, { FC, memo, useEffect, useState, useRef, useCallback } from 'react'
import { default as NextImage } from 'next/image'
import { createPortal } from 'react-dom'
import { useKeenSlider } from 'keen-slider/react'
import { useBlockScrollOverlay } from '@shared/lib/hooks/useBlockScrollOverlay'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Text } from '@shared/ui/Typography/Text'
// @ts-ignore
import IconPlay from '@icons/icon-play.svg'
// @ts-ignore
import IconArrow from '@icons/icon-arrow-gallery.svg'
// @ts-ignore
import IconClose from '@icons/icon--x-thin.svg'

interface Props {
  list: {
    afisha_id: number
    author_object: {
      afisha_id: number
      first_name: string
      last_name: string
    }
    copyright: string
    cover_images: {
      '200x150': string
      '400x300': string
      '100x75': string
    }
    created: string
    description: string
    galleryType: string
    url: string
    priority: number
    height: number
    width: number
  }[]
  selectSlide: number
  handleClose: () => void
}

function ThumbnailPlugin(mainRef) {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

const GalleryModal: FC<Props> = ({ selectSlide, handleClose, list }) => {
  const { isDesktop, isMobile } = useClientResponsive()

  const sliderMainContainer = useRef()
  const [currentSlide, setCurrentSlide] = useState(selectSlide)
  const [modifiedList, setModifiedList] = useState(() => {
    return list.map((item) => ({ ...item, loaded: false }))
  })
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: selectSlide,
    slides: {
      perView: 1,
      spacing: 20,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
      handleStopAllVideo()
    },
  })
  const [thumbnailRef] = useKeenSlider(
    {
      initial: selectSlide,
      slides: {
        origin: isMobile ? 'center' : 'auto',
        perView: 'auto',
        spacing: 8,
      },
    },
    [ThumbnailPlugin(instanceRef)],
  )

  useBlockScrollOverlay()

  useEffect(() => {
    const loadMainImageByIndexes = [currentSlide - 1, currentSlide, currentSlide + 1]

    const changeOneImage = (index, isPriority = false) => {
      if (!modifiedList[index] || modifiedList[index].loaded) return

      const image = new Image()

      image.addEventListener('load', () => {
        setModifiedList((prev) =>
          prev.map((item, prevIndex) => {
            return prevIndex === index ? { ...item, loaded: true } : item
          }),
        )
      })
      // @ts-ignore
      image.fetchPriority = isPriority ? 'high' : 'auto'
      image.src = modifiedList[index].url
    }

    loadMainImageByIndexes.forEach((el) => changeOneImage(el))
  }, [currentSlide])

  const handleClickOutside = (event) => {
    // @ts-ignore
    const sliderClass = Array.from(sliderMainContainer.current.classList)
      .map((el) => `.${el}`)
      .join('.')
    const thumbnailClass = Array.from(instanceRef.current.container.classList)
      .map((el) => `.${el}`)
      .join('.')

    const isOutside = !event.target.closest(sliderClass) && !event.target.closest(thumbnailClass)

    isOutside && handleClose()
  }

  // Функциональность видео
  const [playingVideo, setPlayingVideo] = useState(false)

  const handlePlayVideo = (el, status) => {
    el.stopPropagation()
    const wrapper = el.target.closest(`.${s['modal__main-item']}`)
    const video = wrapper.querySelector('video')

    if (status) {
      video.play()
      setPlayingVideo(true)
    } else {
      video.pause()
      setPlayingVideo(false)
    }
  }

  const handleStopAllVideo = () => {
    // @ts-ignore
    const videos = sliderMainContainer?.current?.querySelectorAll('video')
    videos && videos.forEach((video) => video.pause())
    setPlayingVideo(false)
  }

  // Определение показа кнопок-стрелок при наведении на левую/правую половину фотографии
  const [mouseMoveMainHalves, setMouseMoveMainHalves] = useState({
    left: false,
    right: false,
  })

  const handleMouseMainMove = (e) => {
    const windowWidth = e.view.screen.availWidth
    const leftHalfMove = e.pageX < windowWidth / 2
    const rightHalfMove = e.pageX > windowWidth / 2

    if (mouseMoveMainHalves.left !== leftHalfMove || mouseMoveMainHalves.right !== rightHalfMove) {
      setMouseMoveMainHalves((prev) => ({
        left: leftHalfMove,
        right: rightHalfMove,
      }))
    }
  }

  const handleMouseMainLeave = (e) => {
    setMouseMoveMainHalves((prev) => ({
      left: false,
      right: false,
    }))
  }

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const isEscape = event.code === 'Escape'
    const isLeftArrow = event.code === 'ArrowLeft'
    const isRightArrow = event.code === 'ArrowRight'

    if (isEscape || isLeftArrow || isRightArrow) {
      event.preventDefault()
      event.stopPropagation()

      isEscape && handleClose()
      isLeftArrow && instanceRef.current?.prev()
      isRightArrow && instanceRef.current?.next()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleUserKeyPress)
    return () => {
      document.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <ErrorBoundary>
      {createPortal(
        <div className={s.modal} onClick={handleClickOutside}>
          <div className={s.modal__inner}>
            <button className={s.close} onClick={handleClose}>
              <IconClose />
            </button>

            <div
              ref={sliderMainContainer}
              className={s.modal__main}
              onMouseMove={handleMouseMainMove}
              onMouseLeave={handleMouseMainLeave}>
              {/* ARROWS */}
              {isDesktop && (
                <>
                  <button
                    className={s.modal__control}
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
                    disabled={currentSlide === 0}
                    data-show={mouseMoveMainHalves.left && !playingVideo}>
                    <IconArrow />
                  </button>
                  <button
                    className={s.modal__control}
                    // @ts-ignore
                    onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
                    disabled={currentSlide === modifiedList.length - 1}
                    data-show={mouseMoveMainHalves.right && !playingVideo}>
                    <IconArrow />
                  </button>
                </>
              )}

              <div className={s.modal__progress}>
                <Text sizes='L M' className={s['modal__progress-text']}>
                  {currentSlide + 1} / {modifiedList.length}
                </Text>
              </div>

              {/* MAIN SLIDER */}
              <div ref={sliderRef} className={`keen-slider`}>
                {modifiedList.map((item, index) => {
                  const isVideo = item.galleryType === 'video'

                  return (
                    <div className={`${s['modal__main-item']} keen-slider__slide`} data-video={isVideo} key={index}>
                      <>
                        {isVideo && (
                          <div className={s['modal__main-item-video']} onClick={(el) => handlePlayVideo(el, false)}>
                            <button
                              className={s['modal__main-item-video-button']}
                              onClick={(el) => handlePlayVideo(el, true)}
                              data-show={!playingVideo}>
                              <IconPlay />
                            </button>

                            <video controls={playingVideo}>
                              <source src={item.url} type='video/mp4' />
                            </video>
                          </div>
                        )}
                        {!isVideo && (
                          <div
                            className='photo-wrapper'
                            key={index}
                            data-author={`${item.author_object.first_name} ${item.author_object.last_name}`}>
                            <NextImage
                              src={item.loaded ? item.url : item.cover_images['200x150']}
                              data-loading={!item.loaded}
                              alt='rest-gallery-item'
                              width={400}
                              height={400}
                              sizes='100vw'
                              priority
                              style={{ width: '100%', height: 'auto' }}
                              className={s.modal__mainPhoto}
                            />
                          </div>
                        )}
                      </>
                    </div>
                  )
                })}
              </div>
            </div>

            {/* THUMBNAIL SLIDER */}
            <div className={s.modal__thumbnail}>
              <div ref={thumbnailRef} className={`keen-slider`}>
                {list.map((item, index) => {
                  const isVideo = item.galleryType === 'video'

                  return (
                    <div
                      key={index}
                      className={`${s['modal__thumbnail-item']} keen-slider__slide`}
                      data-video={item.galleryType === 'video'}>
                      <>
                        {isVideo && (
                          <video>
                            <source src={item.url} type='video/mp4' />
                          </video>
                        )}
                        {!isVideo && (
                          <div
                            className='photo-wrapper'
                            key={index}
                            data-author={`${item.author_object.first_name} ${item.author_object.last_name}`}>
                            <NextImage
                              src={item.cover_images['100x75'] || ''}
                              alt='rest-gallery-item'
                              width={0}
                              height={0}
                              sizes='100vw'
                              style={{ width: '100%', height: 'auto' }}
                            />
                          </div>
                        )}
                      </>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )}
    </ErrorBoundary>
  )
}

export default memo(GalleryModal)
