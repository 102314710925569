import s from './SubformMenu.module.sass'

import React, { FC, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { OwnerCalendar } from '@pages/OwnerPersonalPage/ui/components/OwnerCalendar'
import { SubformMenuItem } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/OwnerCreatePostForm/ui/SubformMenu/ui/SubformMenuItem'
import { removeDishSection, setFieldValid, setFieldValue } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'

import { Text } from '@shared/ui/Typography/Text'
import { Button } from '@shared/ui/Actions/Button'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'

// @ts-ignore
import IconPlus from '@icons/icon-owner-plus.svg'
import { addDishSectionAC } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createActions'

const SubformMenu: FC = () => {
  const dispatch = useAppDispatch()
  const editForm = useAppSelector((store) => store?.pages?.owner_personal?.posts?.forms?.edit)
  const { date: dateStore, dishes: dataDishes } = editForm.categories.menu.fields

  const [fieldSelectedDate, setFieldSelectedDate] = useState(dateStore.value ? new Date(dateStore.value) : null)

  const handleAddDish = () => {
    dispatch(addDishSectionAC())
  }

  useEffect(() => {
    // @ts-ignore
    const isValidDate = (d: any) => d instanceof Date && !isNaN(d)

    dispatch(
      setFieldValue({ typeField: 'date', value: isValidDate(fieldSelectedDate) ? fieldSelectedDate.toJSON() : null }),
    )
    // dispatch(setFieldValid({ typeField: 'date', valid: isValidDate(fieldSelectedDate) }))
    dispatch(setFieldValid({ typeField: 'date', valid: true }))
  }, [fieldSelectedDate])

  const handleDeleteDish = (index: number) => {
    dispatch(removeDishSection(index))
  }

  return (
    <>
      <div className={s.wrapper}>
        {/*{!editForm.publicDate && (*/}
        {/*  <div className={s['data-wrap']}>*/}
        {/*    <InputTitle nameField={'owner-date-spec'} title={'Дата публикации'} showRequired={false} />*/}
        {/*    <OwnerCalendar selectedDate={fieldSelectedDate} onChangeDate={(date) => setFieldSelectedDate(date)} />*/}
        {/*  </div>*/}
        {/*)}*/}

        {dataDishes?.length > 0 &&
          dataDishes.map((dish, index) => (
            <SubformMenuItem
              data={dish}
              key={dish.id}
              index={index}
              handleDelete={handleDeleteDish}
              isShowButtonDelete={dataDishes?.length > 1}
            />
          ))}

        {dataDishes.length < 3 && (
          <Button sizes={'XL'} mode={'white-border'} onClick={handleAddDish} className={s['button-add']}>
            <div className={s['button-add__content']}>
              <IconPlus />
              <Text sizes={'M'}>Добавить блюдо</Text>
            </div>
          </Button>
        )}
      </div>
    </>
  )
}

export default SubformMenu
