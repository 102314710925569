/* eslint-disable no-unused-vars */
// @ts-nocheck

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { globalState } from './types'

export const fetchPromo = createAsyncThunk('promo/fetchPromo', async (userId: number, thunkAPI) => {
  const response = await userAPI.fetchById(userId)
  return response.data
})

// ! TODO: сжечь этот костыль, нужно брать город из глобального редьюсера
const isMskHack = typeof window === 'object' && window?.location?.pathname?.indexOf('/msk/') >= 0

const initialState: globalState = {
  list: isMskHack
    ? [
        {
          id: 4,
          descr: 'letnie-verandy-moskvy-2024',
          src: {
            desktop: 'promo-card-summer-terraces--desktop',
            mobile: 'promo-card-summer-terraces--mobile',
          },
          href: 'https://www.afisha.ru/msk/restaurants/letnie-verandy-moskvy-2024/',
        },

        {
          id: 3,
          descr: 'gastrogid',
          src: {
            desktop: 'promo-card-gastrogid--desktop',
            mobile: 'promo-card-gastrogid--mobile',
          },
          href: 'https://t.me/afisharestaurants',
        },

        {
          id: 2,
          descr: 'TgBotDesktop',
          src: {
            desktop: 'promo-card-tg-bot--desktop',
            mobile: 'promo-card-tg-bot--mobile',
          },
          href: 'https://t.me/afisharestaurants',
        },

        {
          id: 1,
          descr: 'owner',
          src: {
            desktop: 'promo-card-owner--desktop',
            mobile: 'promo-card-owner--mobile',
          },
          href: 'https://www.afisha.ru/msk/restaurants/restorator/',
        },
      ]
    : [
        {
          id: 3,
          descr: 'gastrogid',
          src: {
            desktop: 'promo-card-gastrogid--desktop',
            mobile: 'promo-card-gastrogid--mobile',
          },
          href: 'https://t.me/afisharestaurants',
        },

        {
          id: 2,
          descr: 'TgBotDesktop',
          src: {
            desktop: 'promo-card-tg-bot--desktop',
            mobile: 'promo-card-tg-bot--mobile',
          },
          href: 'https://t.me/afisharestaurants',
        },

        {
          id: 1,
          descr: 'owner',
          src: {
            desktop: 'promo-card-owner--desktop',
            mobile: 'promo-card-owner--mobile',
          },
          href: 'https://www.afisha.ru/msk/restaurants/restorator/',
        },
      ],
}

const promo = createSlice({
  name: 'promo',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPromo.fulfilled, (state, action) => {
      // state.list.push(action.payload)
    })
  },
})

export default promo.reducer
