import s from './NewsCardMain.module.sass'
import React, { FC, useRef } from 'react'

import { Title } from '@shared/ui/Typography/Title'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MainCard } from '@shared/ui/Layout/Cards/MainCard'
import { Rating } from '@shared/ui/Feedback/Rating'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'

interface Props {
  data: TypeMappingNewsOutput
  analytic: PickAnalytic<'section_index' | 'section_name'>
}

const NewsCardMain: FC<Props> = ({ data, analytic }) => {
  const { img, url, description, categories } = data
  const thisCoverImage = img.sizes.cover_594x594 || img.sizes.desktop['609x303']
  const href = data.url.path
  const alt = `Фото новости: ${description.title}`
  const cardRef = useRef()
  const handleClick = () => EGG.entity.news.card_click(data, analytic)
  useIntersectionAnalytic(cardRef, () => EGG.entity.news.card_view(data, analytic))

  return (
    <ErrorBoundary>
      <MainCard
        img={thisCoverImage}
        href={href}
        alt={alt}
        classNameContent={s.card}
        chevron={categories.selection}
        onClick={handleClick}
        ref={cardRef}>
        {categories.advert && <Rating isPromo={categories.advert} className={s.promo} />}
        <Title tag='h2' sizes='h2 h3 h5' className={s.title}>
          {description.title}
        </Title>
      </MainCard>
    </ErrorBoundary>
  )
}

export default NewsCardMain
