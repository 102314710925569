import s from './UserMenu.module.sass'
import React, { FC, memo, useState } from 'react'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { MenuLink } from '@widgets/Common/Header/ui/MenuLink'
import { Avatar } from '@shared/ui/Media/Avatar'
import { Modal } from '@shared/ui/Overlays/Modal'
import { Text } from '@shared/ui/Typography/Text'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { EGG } from '@shared/api/analytics'
import { loginAC, logoutAC } from '@app/model/createActions'

const UserMenu: FC = () => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const { global, pages } = useAppSelector((state) => state)
  const { isMobile } = useClientResponsive()
  const dispatch = useAppDispatch()
  const currentPageIsRestItem = global.currentPage === 'rest_item'
  const thisRestId = pages?.rest_item?.data?.id
  const thisRestSlugId = pages?.rest_item?.data?.url?.slug
  const idRestCurrentPage = currentPageIsRestItem && thisRestId
  const router = useRouter()
  const user = global.user.data

  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown)
    if (isOpenDropdown) EGG.accountMenu.button_popup_close_click()
  }

  const handleClickLogin = (ev) => {
    ev.preventDefault()
    EGG.header.button_login_click()
    loginAC()
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (url && newWindow) newWindow.opener = null
  }

  if (!user) {
    return (
      // @ts-ignore
      <MenuLink
        button
        onClick={handleClickLogin}
        text={'Войти'}
        className={s.login}
        data-loading={global.user.loading}
      />
    )
  }

  // if (!user) return <MenuLink button handleClick={() => middlewareAuthorized()} text={'Войти'} />

  const fullName = `${user.first_name} ${user.last_name}`

  const currentRestHasOwner =
    currentPageIsRestItem &&
    // @ts-ignore
    user.owner_rests.some((restIdNum) => parseInt(idRestCurrentPage, 10) === parseInt(restIdNum, 10))

  const hasAccess = {
    special: user?.owner_perms?.has_special_access || false,
    description: user?.owner_perms?.has_description_access || false,
    booking: user?.owner_perms?.has_booking_access || false,
  }

  const isShowPanel = {
    marketing:
      !!idRestCurrentPage &&
      (hasAccess.special || hasAccess.description || user.stf || user.spr || currentRestHasOwner),
    booking: idRestCurrentPage && (hasAccess.special || hasAccess.booking || user.spr || currentRestHasOwner),
    admin: idRestCurrentPage && (hasAccess.special || user.stf || currentRestHasOwner),
  }

  const handleProfile = (ev) => {
    ev.preventDefault
    EGG.accountMenu.button_profile_click()
    router.push(
      `https://www.afisha.ru/personalpage/${user.afisha_id}/`,
      `https://www.afisha.ru/personalpage/${user.afisha_id}/`,
      { scroll: false },
    )
  }

  const handleRestaurateur = (ev) => {
    ev.preventDefault
    if (thisRestSlugId) {
      EGG.accountMenu.button_restaurateur_click()
      openInNewTab(`https://www.afisha.ru/${global.currentCity.slug}/restaurant/${thisRestSlugId}/owner/`)
    }
  }

  const handleBooking = (ev) => {
    ev.preventDefault
    if (thisRestId) {
      EGG.accountMenu.button_booking_click()
      openInNewTab(`https://www.afisha.ru/${global.currentCity.slug}/restaurant/${thisRestId}/owner/booking/`)
    }
  }

  const handleRatings = (ev) => {
    ev.preventDefault
    EGG.accountMenu.button_reviews_click()
    router.push(
      `https://www.afisha.ru/personalpage/${user.afisha_id}/ratings/`,
      `https://www.afisha.ru/personalpage/${user.afisha_id}/ratings/`,
      { scroll: false },
    )
  }

  const handleEditor = (ev) => {
    ev.preventDefault
    EGG.accountMenu.button_editor_click()
    openInNewTab(`https://www.afisha.ru/rests/admin/`)
  }

  const handleSettings = (ev) => {
    ev.preventDefault
    EGG.accountMenu.button_settings_click()
    router.push(`https://id.rambler.ru/account/profile/`)
  }

  const handleLogout = (ev) => {
    ev.preventDefault()
    EGG.accountMenu.button_exit_click()
    dispatch(logoutAC())
  }

  const handleClickProfileAnalytic = () => {
    EGG.accountMenu.button_profile_click()
  }

  const ContentDropDown = () => (
    <>
      <div className={s['dropdown-header']}>
        <Avatar
          sizes='XL L'
          src={user.photo}
          alt={`${fullName} user-avatar`}
          className={s['dropdown-avatar']}
          userName={fullName}
          onClick={handleClickProfileAnalytic}
        />

        <Text sizes='L ML' medium className={s.fullname}>
          {fullName}
        </Text>
      </div>

      <ul className={s['dropdown-main']}>
        <Text sizes='M' tag='li' className={s['dropdown-link']} onClick={handleProfile}>
          <a>{'Профиль'}</a>
        </Text>

        {isShowPanel.marketing && (
          <Text sizes='M' tag='li' className={s['dropdown-link']} onClick={handleRestaurateur}>
            <a>{'Личный кабинет ресторатора'}</a>
          </Text>
        )}

        {isShowPanel.booking && (
          <Text sizes='M' tag='li' className={s['dropdown-link']} onClick={handleBooking}>
            <a>{'Бронирования'}</a>
          </Text>
        )}

        <Text sizes='M' tag='li' className={s['dropdown-link']} onClick={handleRatings}>
          <a>{'Оценки и отзывы'}</a>
        </Text>

        {isShowPanel.admin && (
          <Text sizes='M' tag='li' className={s['dropdown-link']} onClick={handleEditor}>
            <a>{'Редактор материалов'}</a>
          </Text>
        )}

        <Text sizes='M' tag='li' className={s['dropdown-link']} onClick={handleSettings}>
          <a>{'Настройки'}</a>
        </Text>

        <Text sizes='M' tag='li' className={`${s['dropdown-link']}`} onClick={handleLogout}>
          <a>{'Выход'}</a>
        </Text>
      </ul>
    </>
  )

  return (
    <div className={s.user}>
      <div onClick={toggleDropdown} className={s.avatar} data-loading={global.user.loading}>
        <Avatar sizes='M S' src={user.photo} userName={fullName} alt='user-avatar' />
      </div>
      {isOpenDropdown && !isMobile && (
        <div className={s.dropdown}>
          <MenuLink dots onClick={toggleDropdown} dataId={'dots'} selected className={s['dropdown-close']} />
          <ContentDropDown />
        </div>
      )}
      {isOpenDropdown && isMobile && (
        // @ts-ignore
        <Modal handleClose={toggleDropdown}>
          <div className={s.modal}>
            <ContentDropDown />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default memo(UserMenu)
