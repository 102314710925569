/* eslint-disable no-unused-vars */
import DatePicker, { registerLocale } from 'react-datepicker'
import { targetLang } from '../../../lib/booking-localization'
import React, { FC, forwardRef, useState } from 'react'
import BookingCalendarHeader from './BookingCalendarHeader'

import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-GB'
import s from './BookingCalendar.module.sass'

// @ts-ignore
import IconCalendar from '@icons/icon-calendar.svg'
import { TypeDay, TypeDays, TypeSchedules } from '@widgets/Restaurant/Booking/types'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '../../../../../../6_shared/lib/components/ErrorBoundary'

// from react-datepicker
registerLocale('ru', ru)
registerLocale('en', en)

interface Props {
  selectedDate: TypeDay | null
  calendarTarget: boolean
  datesOptions: [] | TypeDays
  schedule: TypeSchedules | null
  handleChangeDate: (selectedDateObj: TypeDay | undefined, selectedDate: Date) => void
  handleCalendarOpened?: (value: boolean) => void
}

const BookingCalendar: FC<Props> = ({
  selectedDate,
  calendarTarget,
  datesOptions,
  schedule,
  handleChangeDate,
  handleCalendarOpened,
}) => {
  const { isDesktop, isMobile } = useClientResponsive()
  const [isCalendarOpened, setIsCalendarOpened] = useState(false)

  const handleOpenCalendar = () => {
    setIsCalendarOpened(true)
    handleCalendarOpened && handleCalendarOpened(true)
  }
  const handleCloseCalendar = () => {
    setIsCalendarOpened(false)
    handleCalendarOpened && handleCalendarOpened(false)
  }

  // кнопка для открытия календаря
  // @ts-ignore
  const CalendarButtonInput = forwardRef(function CalendarButtonInput({ value, onClick }, ref) {
    let calendarButtonClassname = ` ${s.button} ${
      (!isMobile && isCalendarOpened) || calendarTarget ? s['button--red'] : ''
    } `
    if (selectedDate?.itemTextShort) {
      calendarButtonClassname += `${s['button--day']}`
    } else {
      calendarButtonClassname += `${s['button--icon']}`
    }

    return (
      // @ts-ignore
      <button className={calendarButtonClassname} onClick={onClick} ref={ref} type='button'>
        <span className={s['button-text']}>
          {isDesktop && selectedDate?.itemTextShort ? (
            <>
              <IconCalendar />
              {selectedDate?.itemTextShort}
            </>
          ) : (
            <IconCalendar />
          )}
        </span>
      </button>
    )
  })

  // кнопки дат + задание стилей для выходного дня и обычного
  const CalendarDayContent = (day: number, date: Date) => {
    const weekEndClassName = date.getDay() === 0 || date.getDay() === 6 ? 'react-datepicker__day-weekend' : ''
    return <span className={weekEndClassName}>{day}</span>
  }

  const scheduleCalendarFilter = (date: Date) => {
    if (!schedule) return
    const isOpened = !!schedule[date.getDay() === 0 ? 6 : date.getDay() - 1]
    return isOpened
  }

  const handleChangeCalendar = (selectedDate: Date) => {
    const selectedDateObj =
      datesOptions.find((elem) => {
        return (
          // @ts-ignore
          new Date(elem.thisDate).toLocaleDateString('en-US') === new Date(selectedDate).toLocaleDateString('en-US')
        )
      }) || undefined
    handleChangeDate(selectedDateObj, selectedDate)
  }

  return (
    <ErrorBoundary>
      {isMobile && isCalendarOpened && <CalendarButtonInput />}
      <div className={`${s.wrapper} ${isCalendarOpened ? s.opened : ''}`}>
        {datesOptions.length > 0 && (
          <DatePicker
            customInput={<CalendarButtonInput />}
            // inline={} // for dev
            selected={selectedDate ? new Date(selectedDate?.thisDate) : null}
            onChange={(selectedDate: Date) => handleChangeCalendar(selectedDate)}
            onCalendarOpen={handleOpenCalendar}
            onCalendarClose={handleCloseCalendar}
            isClearable={isCalendarOpened}
            calendarStartDay={1}
            locale={targetLang}
            inline={isMobile && isCalendarOpened}
            filterDate={scheduleCalendarFilter}
            renderDayContents={CalendarDayContent}
            minDate={new Date()}
            showPopperArrow={false}
            renderCustomHeader={(data) => (
              <BookingCalendarHeader
                date={data.date}
                changeMonth={data.changeMonth}
                decreaseMonth={data.decreaseMonth}
                increaseMonth={data.increaseMonth}
                prevMonthButtonDisabled={data.prevMonthButtonDisabled}
                nextMonthButtonDisabled={data.nextMonthButtonDisabled}
              />
            )}

            // excludeDateIntervals={[{
            //   start: subDays(new Date(), 5),
            //   end: addDays(new Date(), 5)
            // }]}
          />
        )}
      </div>
    </ErrorBoundary>
  )
}

export default BookingCalendar
