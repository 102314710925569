// Все возможные типы для ClickHouse
export type TypeClickHouseParams = {
  list_name: string // Заголовок на странице листинга и поиска ресторанов (напр. rests_listing)
  city: string // Название города
  name: TypesClickHouseEventName // Тип события
  ab_group: 'a' | 'b' | null // Название группы, в которую был отнесён пользователь.
  ab_name: 'redesign' | null // Название эксперимента в котором принимает участие пользователь.
  afisha_choise: 1 | 0 // Признак выбора афиши у мероприятия или места
  auth: 1 | 0 // Признак авторизации пользователя в момент совершения действия (== срабатывания события).
  role: 'user' | 'restaurateur' // Тип пользователя (!! отправлять только если авторизован)
  baraban_name: string // Название барабана, в котором пользователь совершил действие. Отправляется в паре с параметром block == 'baraban'. Например, барабан Интервью
  block: string // Название блока, в котором произошло событие.
  description: 'afisha' | 'own' // Описание ресторана, персоны
  element: TypesClickHouseElement // Название элемента, на который кликнули или увидели. Применяется во всех события element_click и element_view.
  filter: string // Тип фильтра, который применил пользователь. Отправляется в паре с параметром filter_value.
  filters: string // Название фильтра или список фильтров, которые применены к странице, на которой пользователь совершает действие.
  filter_value: string // Значение фильтра, который применил пользователь. Отправляется в паре с параметром filter.
  cuisine: string // Кухня. Если несколько, то передавать их списком.
  position: string | number // Порядковый номер элемента в блоке или списке, начинается с 1.
  mark: 'yes' | 'no' // Оценка отзыва: yes для положительной оценки, no для отрицательной оценки.
  object_id: string | number // Идентификатор объекта, применим для ресторана, статьи, новости, подборки, персоны
  object_type: TypeClickHouseObjectTypes // Тип объекта
  page_tab: string // Названия табов второго уровня на экране вертикали Рестораны
  rating: string | number // Рейтинг мероприятия или места.
  rating_value: string | number // Рейтинг отзыва
  reserve: 1 | 0 // Наличие возможности забронировать стол в ресторане:
  reserve_status: 'tomesto' | 'rest_widget' // Сервис по бронированию:
  page: TypeClickHouseScreen // Название экрана:
  page_object_type: TypeClickHouseObjectTypes // Тип объекта, на экране которого пользователь совершил действие.
  page_object_id: string | number // Идентификатор объекта, на экране которого пользователь совершил действие.
  social_network: 'tg' | 'vk' | 'zen' // Наименование социальной сети  tg, vk, zen
  sort: 'by_popularity' | 'by_name' | 'by_rating' // Тип сортировки, примененный на экране:
  tab: string // Названия табов первого уровня на экранах вертикалей Афиши (page == main)
  is_promo_card: 0 | 1 // показывает промится ли этот ресторан на сайте (то есть его показывают во всех списках в первую очередь)
  has_promotion: 0 | 1 // наличие спецпредложений у ресторана

  restaurant: string // название ресторана
  restaurant_id: number | string // id ресторана
  element_text: string // информация в поле ввода
  count_guest: number | string
  phone_number: string
  date: string
  time: string
  button_name: string
  tag_name: string
  menu_type: string
  tab_name: string | 'logo'
}

export type TypeClickHouseObjectTypes =
  | 'restaurant'
  | 'article'
  | 'person'
  | 'selection'
  | 'interview'
  | 'menu'
  | 'event'

// Все названия элементов для поля element для ClickHouse
export type TypesClickHouseElement =
  | 'rest_card'
  | 'news_card'
  | 'add_to_fav'
  | 'remove_from_fav'
  | 'promo_card'
  | 'reserve'
  | 'filter'
  | 'selection_card'
  | 'all'
  | 'forward'
  | 'back'
  | 'city'
  | 'search'
  | 'go_to_fav'
  | 'come_in'
  | 'tab'
  | 'right'
  | 'search_result'
  | 'close'
  | 'not_found'
  | 'profile'
  | 'restaurateur_account'
  | 'marks_reviews'
  | 'editor'
  | 'settings'
  | 'exit'
  | 'add_event'
  | 'suggest'
  | 'all_result'
  | 'incorrect_value'
  | 'subscribe'
  | 'info_button'
  | 'social_network'
  | 'share'
  | 'to_reviews'
  | 'description'
  | 'show_schedule'
  | 'hide_schedule'
  | 'special_news'
  | 'menu_item'
  | 'map'
  | 'gallery'
  | 'picture'
  | 'trailer_watch'
  | 'add_review'
  | 'add_rating'
  | 'remove_rating'
  | 'review'
  | 'review_mark'
  | 'q&a'
  | 'page_number'
  | 'show_list'
  | 'hide_list'
  | 'zoom_in'
  | 'zoom_out'
  | 'geolocation'
  | 'news'
  | 'read_news'
  | 'more'
  | 'text_link'
  | 'details'
  | 'read_selection'
  | 'person_card'
  | 'add_guest'
  | 'remove_guest'
  | 'date_today'
  | 'date_tomorrow'
  | 'date'
  | 'time'
  | 'users_name'
  | 'users_phone'
  | 'users_comm'
  | 'email'
  | 'send'
  | 'booking'
  | 'ok'
  | 'tag'
  | 'new_menu_item'
  | 'creation_card'
  | 'ticket'

// Все названия страниц или попапов(reserve, success) для поля page для ClickHouse
export type TypeClickHouseScreen =
  | 'main' // главный экран вертикали ресторанов
  | 'search' // экран поиска
  | 'search_result' // экран с результатами поиска
  | 'profile' // экран профиля и авторизации
  | 'selection_card' // экран подборки
  | 'reviews' // экран с отзывами
  | 'rest_card' // экран ресторана
  | 'restaurant_list' // список ресторанов
  | 'map' // карта
  | 'selected_restaurant' // экран ресторана на карте
  | 'main_news' // экран новостей
  | 'article' // экран статьи
  | 'selection_list' // экран списка подборок
  | 'selection' // экран подборки
  | 'reserve'
  | 'success'

// Названия событий для ClickHouse
export type TypesClickHouseEventName =
  | 'screen_appear' // страница загрузилась
  | 'block_appear' // блок загружен\отображен
  | 'element_view' // элемент в поле зрения
  | 'element_click' // клик на элемент
  | 'type_text' // ввод текста в поле
  | 'experiment' // Начало визита. Если пользователь участвует в нескольких экспериментах, то под каждое отправляем событие
  | 'error' // для ошибок

// На фронте реализован собственный список параметров, чтобы не зависеть наименования аналитиков.
// Поэтому на всем проекте используются и можно использовать только эти параметры
// Здесь происходит сопоставление типов фронтовых и СlickHouse
export type TypeAnalyticParams = {
  listing_title: TypeClickHouseParams['list_name']
  city_name: TypeClickHouseParams['city']
  event_name: TypeClickHouseParams['name']
  ab_group: TypeClickHouseParams['ab_group']
  ab_name: TypeClickHouseParams['ab_name']
  afisha_selected: boolean
  is_auth: boolean
  slider_name: TypeClickHouseParams['baraban_name']
  section_name: TypeClickHouseParams['block']
  section_type: 'main_slider' | string
  section_index: TypeClickHouseParams['position']
  description: TypeClickHouseParams['description']
  element_type: TypeClickHouseParams['element']
  filter_apply_type: TypeClickHouseParams['filter']
  filters_apply: TypeClickHouseParams['filters']
  filter_apply_value: TypeClickHouseParams['filter_value']
  cuisine: TypeClickHouseParams['cuisine']
  review_score: TypeClickHouseParams['mark']
  object_id: TypeClickHouseParams['object_id']
  object_type: TypeClickHouseParams['object_type']
  page_active_tab: TypeClickHouseParams['tab']
  inner_active_tab: TypeClickHouseParams['page_tab']
  rating: TypeClickHouseParams['rating']
  rating_action: TypeClickHouseParams['rating_value']
  has_reserve: boolean
  reserve_service: TypeClickHouseParams['reserve_status']
  page_name: TypeClickHouseParams['page']
  page_object_type: TypeClickHouseParams['page_object_type']
  page_object_id: TypeClickHouseParams['page_object_id']
  social_network: TypeClickHouseParams['social_network']
  sort_type: TypeClickHouseParams['sort']
  is_promo_card: boolean
  has_promo_offer: boolean

  restaurant_name: TypeClickHouseParams['restaurant']
  restaurant_id: TypeClickHouseParams['restaurant_id']
  input: TypeClickHouseParams['element_text']
  count_guest: TypeClickHouseParams['count_guest']
  phone: TypeClickHouseParams['phone_number']
  date: TypeClickHouseParams['date']
  time: TypeClickHouseParams['time']
  button_name: TypeClickHouseParams['button_name']
  tag_name: TypeClickHouseParams['tag_name']
  menu_type: TypeClickHouseParams['menu_type']
  navigation_name: TypeClickHouseParams['tab_name']
}

// Позволяет вытащить из типа TypeAnalyticParams переданные параметры и сделать их обязательными или необязательными. + сохраняет тип данных ключей
// Пример --> PickAnalytic<'element' | 'position', 'restaurant'>
// Вернет --> {element: string, position: string, restaurant?: string}
export type PickAnalytic<K extends keyof TypeAnalyticParams = null, D extends keyof TypeAnalyticParams = null> = {
  [P in K]: TypeAnalyticParams[P]
} & {
  [J in D]?: TypeAnalyticParams[J]
}

// Объект нужен для сопоставления и проверки ключей фронт -> ClickHouse на этапе составления итоговых данных для запроса
export const ClickHouseMappingParams = {
  listing_title: 'list_name',
  event_name: 'name',
  city_name: 'city',
  ab_group: 'ab_group',
  ab_name: 'ab_name',
  afisha_selected: 'afisha_choise',
  is_auth: 'auth',
  slider_name: 'baraban_name',
  section_name: 'block',
  section_index: 'position',
  description: 'description',
  element_type: 'element',
  filter_apply_type: 'filter',
  filters_apply: 'filters',
  filter_apply_value: 'filter_value',
  cuisine: 'cuisine',
  review_score: 'mark',
  object_id: 'object_id',
  object_type: 'object_type',
  page_active_tab: 'tab',
  inner_active_tab: 'page_tab',
  rating: 'rating',
  rating_action: 'rating_value',
  has_reserve: 'reserve',
  reserve_service: 'reserve_status',
  page_name: 'page',
  page_object_type: 'page_object_type',
  page_object_id: 'page_object_id',
  social_network: 'social_network',
  sort_type: 'sort',
  is_promo_card: 'is_promo_card',
  has_promo_offer: 'has_promotion',
  input: 'element_text',
  restaurant_name: 'restaurant',
  restaurant_id: 'restaurant_id',
  count_guest: 'count_guest',
  phone: 'phone_number',
  date: 'date',
  time: 'time',
  button_name: 'button_name',
  tag_name: 'tag_name',
  menu_type: 'menu_type',
  navigation_name: 'tab_name',
}

export type TypeYandexMetricParams = {
  id: number
  type: 'reachGoal'
  event: string
}
