import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { NewsList } from '@shared/api/types/swaggerTypes'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TARGET_SELECTIONS } from '@shared/api/middleware/mappingAPI/patchStickersSelections'

export const mappingNewsItem = (object: NewsList): TypeMappingNewsOutput => {
  const getFormattedMarkup = (markup) => {
    const object = JSON.parse(markup.slice(1, -1))

    return object.map((item) => {
      if (item.type === 'text' || item.type === 'quote') {
        const textRemoveBr = item.data.text.replaceAll('<br>', '')

        const regFindOpenAndCloseTags =
          /(?<openTag>^<(?<openTagName>\w+)(?:[^>]+?)?>)(?<innerContent>.+)(?<closeTag><\/(?<closeTagName>.+)>$)/m

        // удаление первого тега. Обычно это <p>
        const firstTag = textRemoveBr.match(regFindOpenAndCloseTags)?.groups

        if (firstTag?.openTagName === 'p' && firstTag?.openTagName === firstTag?.closeTagName) {
          item.data.text = textRemoveBr.replace(regFindOpenAndCloseTags, '$<innerContent>')
        }

        // Если есть вложенный тег-обертка то убираем его и устанавливаем его название в ключ type
        const secondTag = item.data.text.match(regFindOpenAndCloseTags)?.groups
        if (secondTag?.openTagName && secondTag?.openTagName === secondTag?.closeTagName) {
          item.data.text = item.data.text.replace(regFindOpenAndCloseTags, '$<innerContent>')
          item.data.tag = secondTag.openTagName
        } else item.data.tag = 'p'
      }

      return item
    })
  }

  try {
    return undefinedObjectToNull({
      id: object.id,
      url: {
        path: object.url,
        slug: object.url.split('/')[1],
      },

      description: {
        title: object.title,
        announce: object.announce,
      },

      // ! TODO: необходимо исследовать, почему в данный type объект
      //    пришлось добавить оператор опциональной цепочки (.?),
      //    возможно его необходимо добавить везде в данной функции
      type: {
        name: object?.variety?.name_in_plural,
        slug: object?.variety?.slug,
      },

      categories: {
        selection: TARGET_SELECTIONS.includes(object.url.split('/')[1]),
        advert: object.advert,
      },

      date: object.date_local,
      // @ts-ignore
      dateUNIX: object.date * 1000,
      img: {
        name: object.cover.name,
        author: object.cover.author,
        caption: object.cover.caption,
        alt: object.cover.alt,
        sizes: {
          cover_594x594: object.cover.cover_594x594,
          cover_1220x594: object.cover.cover_1220x594,
          cover_2210x1244: object.cover.cover_2210x1244,
          desktop: {
            default1: object.cover.cover1,
            default2: object.cover.cover2,
            '273x182': object.cover.cover_273x182,
            '303x303': object.cover.cover_303x303,
            '609x303': object.cover.cover_609x303,
          },
          mobile: {
            '224x168': object.cover.mobile_cover_224x168,
            '1600x384': object.cover.mobile_cover_1600x384,
            '1600x432': object.cover.mobile_cover_1600x432,
          },
        },
      },
      author: object.author
        ? {
            id: object.author.id,
            name: object.author.name,
            slug: object.author.slug,
            avatar: object.author.afisha_user_photo,
          }
        : null,

      // @ts-ignore
      restaurants: object.restaurants.map((rest) => ({
        id: rest.id,
        name: rest.name,
        url: {
          slug: rest.url_slug,
          path: rest.abs_url,
        },
      })),
      markup: getFormattedMarkup(object.text),
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
