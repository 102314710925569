import s from './OwnerHomePage.module.sass'

import React, { useEffect, useState } from 'react'
import Image from 'next/image'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { initPostsPage } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/createActions'
import { initReviews } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/createActions'
import { OwnerPublishedOffer } from '@pages/OwnerPersonalPage/ui/components/OwnerPublishedOffer'
import { RestSelectionItem } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage/ui/RestSelectionItem'
import { StatisticFromYM } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage/ui/StatisticFromYM'
import { StatisticItem } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage/ui/StatisticItem'

import { Reviews } from '@entities/Restaurant/ReviewsNew'

import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { PopupTrigger } from '@shared/ui/Overlays/Popup/ui/PopupTrigger'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Text } from '@shared/ui/Typography/Text'
import { Popup } from '@shared/ui/Overlays/Popup'

// @ts-ignore
import IconArrowDown24 from '@icons/icon-select-arrow-down-24.svg'
import { changeComponentCreate } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/reducer'
import { sendCreateAnswer } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/createAsyncThunk'

const IS_SUPERUSER = false
const IS_STATS_ACTIVE = false
const IS_CHANGE_REST_ENABLED = false

const getAccessText = (thisAccessState) => {
  const accessDateGranted = '25.06.2023'
  const accessDateExpire = '00-00 25.06.2023'
  const accessTextWithDate = thisAccessState
    ? `Доступ открыт до ${accessDateGranted}`
    : `Доступ скоро закончится в ${accessDateExpire}`
  const hasAccessDate = false
  const accessTextOutput = hasAccessDate ? accessTextWithDate : 'Доступ открыт'
  return accessTextOutput
}

/**
 * @function OwnerTabHome - Главная
 */
const OwnerHomePage = (props) => {
  const stateOwner = useAppSelector((store) => store.pages.owner_personal)
  const dispatch = useAppDispatch()

  const reviewsByFilters = stateOwner?.reviews?.reviewsByFilters

  // @ts-ignore
  const { can_published, published } = stateOwner.posts

  useEffect(() => {
    dispatch(initReviews({ restaurant: { id: props.data.id, name: props.data.name }, unansweredMode: true }))
    dispatch(initPostsPage({ id: props.data.id, name: props.data.name }))
  }, [])

  const [isOpenRestaurantChange, setIsOpenRestaurantChange] = useState(false)
  // TODO avatar
  const restAvatarPhoto = null || getPlaceholderImage(props.data.id, 'square')

  const restName = props?.data?.name || 'Ресторан'
  const restAddress = props?.data?.address?.address || ''

  const accessState = true
  const accessText = getAccessText(accessState)
  const accessDateClassName = `${s['rest-info-access-date']} ${
    accessState ? s['rest-info-access-date--green'] : s['rest-info-access-date--red']
  }`

  const handleSendAnswer = (data, comment) => {
    dispatch(changeComponentCreate({ body: comment, loading: true }))
    // @ts-ignore
    dispatch(sendCreateAnswer(data))
  }

  const ownerProps = {
    options: {
      loading: false,
      answerPossible: true,
      showSettings: true,
    },
    events: {
      onClickOpenSendAnswer: () => console.log('onClickOpenSendAnswer'),
      onClickSendAnswer: handleSendAnswer,
      onClickDeleteAnswer: () => console.log('onClickDeleteAnswer'),
      onClickEditAnswer: () => console.log('onClickEditAnswer'),
      onInputValueAnswer: () => console.log('onInputValueAnswer'),
    },
  }

  const commonProps = {
    options: {
      style: 'row' as 'row' | 'card',
    },
    events: {
      intersectionAnalyticMethod: () => console.log('intersectionAnalyticMethod'),
      onClickFullAnswer: () => console.log('onClickFullAnswer'),
      onClickDeleteReview: () => console.log('onClickDeleteReview'),
      onClickEditReview: () => console.log('onClickEditReview'),
      onClickFullReview: () => console.log('onClickFullReview'),
      onClickOpenSendAnswer: () => console.log('onClickOpenSendAnswer'),
    },
  }

  const restInfoMainDataClassName = `${s['rest-info-main-data']}`
  // const restInfoMainDataClassName = `${s['rest-info-main-data']} ${
  //   restAvatarPhoto ? '' : s['rest-info-main-data--no-photo']
  // }`

  return (
    <>
      <div className={s['page-content']}>
        <section className={s['page-content-section']}>
          <div className={s['page-content-white-block']}>
            <div className={restInfoMainDataClassName}>
              <div className={s['rest-info-logo-wrap']}>
                {restAvatarPhoto && (
                  <Image
                    className={s['rest-info-logo-image']}
                    src={restAvatarPhoto || ''}
                    alt={'Логотип ресторана ' + restName}
                    layout={'fill'}
                  />
                )}
              </div>

              <div className={s['rest-info-wrap']}>
                <Title sizes={'h2'} tag={'h2'} className={s['rest-info-name']}>
                  {restName}
                </Title>

                <Text sizes={'S'} className={s['rest-info-address']}>
                  {restAddress}
                </Text>

                <div className={s['rest-info-access-date-wrap']}>
                  <Text className={accessDateClassName} sizes={'S'}>
                    {accessText}
                  </Text>
                </div>
              </div>

              {IS_CHANGE_REST_ENABLED && (
                <Popup
                  open={isOpenRestaurantChange}
                  onOpenChange={(value) => setIsOpenRestaurantChange(value)}
                  placement={'bottom-end'}
                  offset={10}
                  flip={false}>
                  <PopupTrigger>
                    <Button
                      sizes={'L'}
                      mode={'white-border'}
                      className={s['rest-info-toggle-button']}
                      onClick={() => setIsOpenRestaurantChange(!isOpenRestaurantChange)}>
                      <span className={s['rest-info-toggle-icon']}>
                        <IconArrowDown24 />
                      </span>
                    </Button>
                  </PopupTrigger>

                  <PopupContent className={s['rest-selection-block-wrapper']}>
                    <RestSelectionItem
                      name={'O2 Lounge'}
                      address={'Москва, Тверская, 3, гостиница The Carlton, 12 этаж'}
                      slug={'test'}
                    />

                    <RestSelectionItem name={'O2 Lounge'} address={'Москва, Петровский б-р, 14'} slug={'test'} />

                    <RestSelectionItem
                      name={'O2 Lounge'}
                      address={'Москва, Краснопрудная, 12, гостиница Moscow Marriott Imperial Plaza'}
                      slug={'test'}
                    />
                  </PopupContent>
                </Popup>
              )}
            </div>
          </div>
        </section>

        {IS_STATS_ACTIVE && (
          <>
            {IS_SUPERUSER ? (
              <>
                <section className={s['page-content-section']}>
                  <Title sizes={'h5'} tag={'h3'} className={s['stats-title']}>
                    Показы в динамике
                  </Title>

                  <div className={s['stats-block']}>
                    <StatisticItem title={'Показы «Места рядом»'} value={6476} diff={25} />
                    <StatisticItem title={'Показы «Похожие»'} value={37951} diff={12} />
                    <StatisticItem title={'Показы «Поиск»'} value={88678} diff={-8} />
                    <StatisticItem title={'Показы, динамика по сайту:'} value={133106} diff={-8} />
                    <StatisticItem title={'Показы, динамика в подборках:'} value={54147} diff={2} />
                    <StatisticItem title={'Показы всего:'} value={187123} diff={12} />
                  </div>
                </section>

                <section className={s['page-content-section']}>
                  <Title sizes={'h5'} tag={'h3'} className={s['stats-title']}>
                    Клики
                  </Title>

                  <div className={s['stats-block']}>
                    <StatisticItem title={'Клики по карточке «Промо»'} value={6476} diff={25} />
                    <StatisticItem title={'Клики по карточке «Спецпредложения»'} value={37951} diff={12} />
                    <StatisticItem title={'Клики по кнопке «Позвонить»'} value={88678} diff={-8} />
                    <StatisticItem title={'Клики по кнопке «Перейти на сайт»'} value={133106} diff={-8} />
                    <StatisticItem title={'Клики по кнопке «Перейти в соц.сеть»'} value={54147} diff={2} />
                    <StatisticItem title={'Клики по кнопке «Забронировать»'} value={187123} diff={12} />
                    <StatisticFromYM className={s['stats-block-source']} />
                  </div>
                </section>
              </>
            ) : (
              <section className={s['page-content-section']}>
                <Title sizes={'h5'} tag={'h3'} className={s['stats-title']}>
                  Статистика за неделю
                </Title>

                <div className={s['stats-block']} data-two-block={true}>
                  <StatisticItem title={'Показы «Места рядом»'} value={6476} diff={25} />
                  <StatisticItem title={'Показы «Похожие»'} value={37951} diff={12} />
                  <StatisticItem title={'Показы «Поиск»'} value={88678} diff={-8} />
                  <StatisticItem title={'Показы, динамика по сайту:'} value={133106} diff={-8} />
                  <StatisticItem title={'Показы, динамика в подборках:'} value={54147} diff={2} />
                  <StatisticItem title={'Показы всего:'} value={187123} diff={12} />
                </div>

                <StatisticFromYM className={s['stat-source-descr']} />
              </section>
            )}
          </>
        )}

        {!!stateOwner.posts.published && (
          <section className={s['page-content-section']}>
            <OwnerPublishedOffer />
          </section>
        )}

        {reviewsByFilters?.length > 0 && (
          <section className={s['page-content-section']}>
            <Title sizes={'h5'} tag={'h3'}>
              Отзывы без ответа
            </Title>

            <div className={s['review-data-wrap-margin']}>
              <div className={s['page-content-white-block']}>
                {reviewsByFilters.map((review, i) => {
                  const sourceData = {
                    restName: props.data.name,
                    reviewData: review,
                  }

                  return (
                    i === 0 && (
                      <div className={s['review-data-item']} key={i}>
                        <Reviews.Item
                          sourceData={sourceData}
                          ownerProps={ownerProps}
                          commonProps={commonProps}
                          key={review.id}
                        />
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  )
}

export default OwnerHomePage
