import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypeMappingRestOutput, TypeMappingRestTag } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { TypeState } from '@pages/AppHome/model/types'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypeQuickFilter } from '@shared/api/middleware/mappingAPI/city/types'

const initialState: TypeState = {
  data: null,

  sections: {
    featureRests: {
      loading: true,
      list: null,
    },
    quickFilters: {
      loading: true,
      list: null,
    },
    tags: {
      loading: true,
      list: null,
    },
    specialRests: {
      loading: true,
      list: null,
    },
    ratingRests: {
      loading: true,
      list: null,
    },
    afishaSelections: {
      loading: true,
      list: null,
    },
    weekPlans: {
      loading: true,
      data: null,
    },
    restaurantsNews: {
      loading: true,
      list: null,
    },
    otherNews: {
      loading: true,
      list: null,
    },
  },
}

const appHome = createSlice({
  name: 'appHome',
  initialState,

  reducers: {
    setFeatureRests(state, action: PayloadAction<TypeMappingRestOutput[]>) {
      state.sections.featureRests.loading = false
      state.sections.featureRests.list = action.payload || null
    },
    setQuickFilters(state, action: PayloadAction<TypeQuickFilter[]>) {
      state.sections.quickFilters.loading = false
      state.sections.quickFilters.list = action.payload || null
    },
    setTags(state, action: PayloadAction<TypeMappingRestTag[]>) {
      state.sections.tags.loading = false
      state.sections.tags.list = action.payload || null
    },

    setSpecialRests(state, action: PayloadAction<TypeMappingRestOutput[]>) {
      state.sections.specialRests.loading = false
      state.sections.specialRests.list = action.payload || null
    },
    setRatingRests(state, action: PayloadAction<TypeMappingRestOutput[]>) {
      state.sections.ratingRests.loading = false
      state.sections.ratingRests.list = action.payload || null
    },
    setAfishaSelections(state, action: PayloadAction<TypeMappingSelectionOutput[]>) {
      state.sections.afishaSelections.loading = false
      state.sections.afishaSelections.list = action.payload || null
    },

    setRestaurantsNews(state, action: PayloadAction<TypeMappingNewsOutput[]>) {
      state.sections.restaurantsNews.loading = false
      state.sections.restaurantsNews.list = action.payload || null
    },
    setOtherNews(state, action: PayloadAction<TypeMappingNewsOutput[]>) {
      state.sections.otherNews.loading = false
      state.sections.otherNews.list = action.payload || null
    },

    setWeekPlans(state, action: PayloadAction<TypeMappingNewsOutput>) {
      state.sections.weekPlans.loading = false
      state.sections.weekPlans.data = action.payload || null
    },

    resetState() {
      return initialState
    },
  },
})

export const getState = (state) => state

export const {
  setFeatureRests,
  setQuickFilters,
  setTags,
  setSpecialRests,
  setRatingRests,
  setAfishaSelections,
  setRestaurantsNews,
  setOtherNews,
  setWeekPlans,
  resetState,
} = appHome.actions

export default appHome.reducer
