import React, { useRef } from 'react'

import { useAppSelector } from '@app/model/store'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MediaCard } from '@shared/ui/Layout/Cards/MediaCard'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'

// 1x1, 2x1 for mobile
// type size = 'L1x1 L2x1 M1x1 M2x1 S1x1 1x1 2x1'
interface NewsCardProps {
  data: TypeMappingNewsOutput
  className?: string
  sizes: string
  analytic: PickAnalytic<'section_index' | 'section_name'>
}

function NewsCard({ data, className = '', analytic, sizes }: NewsCardProps) {
  const stateGlobal = useAppSelector((state) => state.global)
  const { currentCity } = stateGlobal
  const cardRef = useRef()

  const handleClick = () => EGG.entity.news.card_click(data, analytic)
  useIntersectionAnalytic(cardRef, () => EGG.entity.news.card_view(data, analytic))

  return (
    <ErrorBoundary>
      <MediaCard
        id={String(data.id)}
        sizes={sizes}
        title={data.description.title}
        // @ts-ignore
        type={{ name: data.type.name, url: `/${currentCity.slug}/restaurant-news/?variety=${data.type.slug}` }}
        date={data.date}
        img={{
          desktop: {
            horizontal:
              data.img.sizes.cover_1220x594 || data.img.sizes.desktop.default1 || data.img.sizes.desktop['609x303'],
            vertical: data.img.sizes.cover_594x594 || data.img.sizes.desktop['303x303'],
          },
          mobile: {
            vertical:
              data.img.sizes.cover_594x594 || data.img.sizes.desktop['303x303'] || data.img.sizes.mobile['224x168'],
          },
        }}
        url={data.url.path}
        promo={data.categories.advert}
        className={className}
        onClick={handleClick}
        ref={cardRef}
      />
    </ErrorBoundary>
  )
}

export default NewsCard
