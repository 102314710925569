import { RestaurantRetrieve } from '@shared/api/types/swaggerTypes'
import { generateScheduleWorkDays } from '@shared/lib/helpers/schedule'
import { undefinedObjectToNull } from '@shared/api/middleware/mappingAPI/helpers'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

// @ts-ignore
import MenuImage1 from '@images/menu-items/desktop/menu-image-1.png'
// @ts-ignore
import MenuImage2 from '@images/menu-items/desktop/menu-image-2.png'
// @ts-ignore
import MenuImage3 from '@images/menu-items/desktop/menu-image-3.png'
// @ts-ignore
import MenuImage4 from '@images/menu-items/desktop/menu-image-4.png'
// @ts-ignore
import MenuImage5 from '@images/menu-items/desktop/menu-image-5.png'
// @ts-ignore
import MenuImage6 from '@images/menu-items/desktop/menu-image-6.png'
// @ts-ignore
import MenuImage7 from '@images/menu-items/desktop/menu-image-7.png'
// @ts-ignore
import MenuImage8 from '@images/menu-items/desktop/menu-image-8.png'
// @ts-ignore
import MenuImage9 from '@images/menu-items/desktop/menu-image-9.png'

export const mappingRestaurantItem = (object: RestaurantRetrieve): TypeMappingRestOutput => {
  try {
    const getStatus = (data) => {
      const names = {
        0: 'Открыт',
        1: 'Ресторан временно закрыт',
        2: 'Ресторан закрыт',
        10: 'Ресторан удален в Афише',
      }

      const restaurantIsOpenSoon = (open) => {
        const openDate = new Date(open)
        const currentDate = new Date(Date.now())
        const nextDayOpenDate = new Date(openDate)
        nextDayOpenDate.setDate(openDate.getDate() + 1)

        return openDate > currentDate && nextDayOpenDate > currentDate
      }

      const statusOpenDate = restaurantIsOpenSoon(data.open_date)

      if (statusOpenDate) {
        return {
          code: 3,
          name: `Открытие
            ${new Date(data.open_date).toLocaleDateString('Ru', {
              month: 'long',
              day: 'numeric',
            })}
            ${new Date(data.open_date).getFullYear()}`,
          open: false,
          temporarily: false,
          openingSoon: true,
        }
      }

      return {
        code: data.state,
        name: names[data.state],
        open: [0, 1].includes(data.state),
        temporarily: data.state === 1,
        openingSoon: false,
      }
    }

    const formattedSchedule = object.working_hours_objects
      ? generateScheduleWorkDays(object.working_hours_objects)
      : null
    // const whenRestOpensString = formattedSchedule ? getShortTextSchedule(formattedSchedule) : null
    // const todaySchedule = formattedSchedule ? formattedSchedule[new Date(Date.now()).getDay() - 1] : null

    const getFormattedMetro = (metroList) => {
      const prepareMetroColors = (colorValue) => {
        const defaultMetroColor = '262626'
        return Array.isArray(colorValue)
          ? [...colorValue.map((thisColor) => `#${thisColor || defaultMetroColor}`)]
          : [`#${colorValue || defaultMetroColor}`]
      }

      if (metroList?.length) {
        return metroList.map((item) => {
          const { hex_color, ...other } = item
          return { ...other, color: prepareMetroColors(hex_color) }
        })
      } else return null
    }

    const getUserData = (data) => {
      if (data) {
        const hasReview = !!data.review_id
        return {
          favourite: data.is_favourite,
          like: data.like,
          review: hasReview
            ? {
                id: data.review_id,
                like: data.review_like,
              }
            : null,
        }
      } else return null
    }

    const generateMenuPDF = (menuArray) => {
      const menuTemplate = {
        breakfast: {
          img: MenuImage1.src,
          keyWords: ['Завтрак', 'breakfast'],
        },
        bar: {
          img: MenuImage2.src,
          keyWords: ['Бар', 'Барная карта', 'bar'],
        },
        child: {
          img: MenuImage4.src,
          keyWords: ['Детское', 'child'],
        },
        main: {
          img: MenuImage7.src,
          keyWords: ['Основное меню', 'Главное', 'Main'],
        },
        lunch: {
          img: MenuImage8.src,
          keyWords: ['Обед', 'Ланч', 'lunch'],
        },
        cocktails: {
          img: MenuImage3.src,
          keyWords: ['Коктейл', 'cocktail'],
        },
        wine: {
          img: MenuImage6.src,
          keyWords: ['Вино', 'Винная карта', 'Винное', 'wine'],
        },
        tea: {
          img: MenuImage9.src,
          keyWords: ['Чай', 'tea'],
        },
        other: {
          img: MenuImage5.src,
          keyWords: null,
        },
      }

      if (!menuArray?.length) return null

      let countMainMenu = 0
      for (let i = 0; i < menuArray.length; i++) {
        const getMenuTemplate =
          Object.values(menuTemplate).find((el) => {
            return (
              el.keyWords && el.keyWords.some((word) => menuArray[i].type.toLowerCase().includes(word.toLowerCase()))
            )
          }) || menuTemplate.other

        menuArray[i] = {
          id: i,
          type: menuArray[i].type,
          href: menuArray[i].href?.replace('//develop.rests', '//rests'),
          img: getMenuTemplate.img,
        }

        // Выносим основное меню в начало списка
        // Если в списке есть несколько основных меню (напр. ру. и англ.) то выносятся вперед оба
        const isMainMenu = menuTemplate.main.keyWords.some((word) =>
          menuArray[i].type.toLowerCase().includes(word.toLowerCase()),
        )

        if (isMainMenu) {
          const temporary = menuArray[countMainMenu]
          menuArray[countMainMenu] = menuArray[i]
          menuArray[i] = temporary
          countMainMenu += 1
        }
      }

      return menuArray
    }

    const generateNewDishes = (data) => {
      if (data?.items?.length > 0) {
        return data.items.map((dishItem, i) => {
          return {
            id: dishItem.id || i,
            title: dishItem.title,
            price: dishItem.price,
            img: dishItem.photo,
            description: dishItem.description,
          }
        })
      } else return null
    }

    const genPhoneArray = (phoneData) => {
      return !(typeof phoneData === 'string') ? phoneData : phoneData.split(',').map((phone) => phone.trim())
    }

    const isDescrExist = (data) => {
      const regExp = /[а-яА-ЯёЁa-zA-Z]/g
      const textExist = !!data && regExp.test(data)
      return textExist ? data.trim() : null
    }

    const getValueDiscount = (string) => {
      try {
        return string ? string.match(/\d+/i)[0] : null
      } catch (e) {
        return null
      }
    }

    const getMessageForAlertBooking = () => {
      try {
        const discountMessage = getValueDiscount(object.remarked_booking_object?.discount_label)

        if (discountMessage) {
          return `Скидка ${discountMessage}₽ на основное меню при первом бронировании на сайте Афиша Рестораны`
        }
        return null
      } catch (e) {
        return null
      }
    }

    return undefinedObjectToNull({
      id: object.id,
      name: object.name,
      type: object.restaurant_type,
      status: getStatus(object),

      menu: generateMenuPDF(object.menu_objects),

      discounts: {
        tastyclub: object.tastyclub_data,
        // @ts-ignore
        remarked: getValueDiscount(object.remarked_booking_object?.discount_label),
      },

      deposit: {
        // @ts-ignore
        active: object.remarked_booking_object?.has_deposit,
        // @ts-ignore
        text: object.remarked_booking_object?.deposit_text || null,
      },

      categories: {
        advert: object.is_advert,
        selection: object.has_selection,
      },

      description: {
        title: isDescrExist(object.verdict),
        description: {
          owner: isDescrExist(object.owner_info?.description),
          afisha: isDescrExist(object.description),
        },
      },

      user: getUserData(object.userdata),

      contacts: {
        phone: {
          // @ts-ignore
          main: object.extra_info.phone,
          // @ts-ignore
          list: object.extra_info.phone_list || genPhoneArray(object.extra_info?.phone),
        },
        // @ts-ignore
        email: object.extra_info.email,
        // @ts-ignore
        site: object.extra_info.site,
      },

      address: {
        city: {
          id: object.city_id,
        },
        address: object.address,
        // @ts-ignore
        metro: getFormattedMetro(object.tags.metro),
        coordinates: [object.longitude, object.latitude],
      },

      images: {
        main: object.main_photo_legacy,
        cover: object.cover_images,
      },

      url: {
        slug: object.url_slug,
        path: object.abs_url,
      },

      booking: {
        // Бронирование через сайт Афиши
        afisha: {
          remarked: {
            active: !!object.remarked_booking_object?.is_active,
          },
          active: object.has_afisha_booking,
          alert: object.afisha_booking_object
            ? {
                active: !!object.afisha_booking_object?.is_active,
                message: object.afisha_booking_object?.widget_message,
              }
            : null,
        },
        // Бронирование через сервис tomesto
        tomesto: {
          active: object.has_tomesto,
          url: object.tomesto_url,
        },
      },

      offers: {
        special: object.offer_special,
        dishes: generateNewDishes(object.offer_menu),
        news: object.offer_news,
      },

      feedback: {
        rating: object.rating,
        likes: object.likes,
        dislikes: object.dislikes,
        reviews: {
          count: object.reviews_count,
        },
      },

      price: {
        name: {
          // @ts-ignore
          long: object.tags.average_bill?.name,
          // @ts-ignore
          short: object.tags.average_bill?.alt_name,
        },
        // @ts-ignore
        level: object.tags.price_range?.length,
        tag: {
          // @ts-ignore
          id: object.tags.average_bill?.id,
          // @ts-ignore
          url: object.tags.average_bill?.url,
        },
      },

      schedule: {
        // @ts-ignore
        common: object.extra_info.work_time,
        week: formattedSchedule,
        // source: object.working_hours_objects,
      },

      services: {
        // @ts-ignore
        breakfast: Boolean(object.extra_info.breakfast),
        // @ts-ignore
        lunch: Boolean(object.extra_info.business_lunch),
        // @ts-ignore
        delivery: Boolean(object.extra_info.delivery),
        // @ts-ignore
        parking: Boolean(object.extra_info.parking),
        // @ts-ignore
        banquet: Boolean(object.extra_info.has_banquet),
        // @ts-ignore
        catering: Boolean(object.extra_info.catering),
      },

      tags: object.tags,
    })
  } catch (error) {
    console.error(error)
    return null
  }
}
