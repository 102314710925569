import React, { FC, HTMLProps, ReactNode } from 'react'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import s from './MainCard.module.sass'
import Image from 'next/image'
import Link from 'next/link'
// @ts-ignore
import IconChevronLong from '@icons/icon-selection-chevron--big.svg'

interface Props {
  img: string
  alt: string
  href: string
  sizes?: string // XL - desktop, S - mobile
  chevron?: boolean
  classNameWrapper?: string
  classNameContent?: string
  children: ReactNode
}

const MainCard: FC<Props & HTMLProps<HTMLDivElement>> = React.forwardRef(
  (
    { img, href, alt, sizes = 'XL S', chevron, classNameWrapper = '', classNameContent = '', children, ...props },
    ref,
  ) => {
    const [size] = useCurrentSize(sizes)

    return (
      <ErrorBoundary>
        {/*// @ts-ignore*/}
        <Link
          href={href}
          className={`${s.card} ${classNameWrapper}`}
          data-chevron={chevron}
          data-cover-size={size}
          scroll={false}
          {...props}>
          {chevron && <IconChevronLong className={s.chevron} />}
          <div className={s['img-wrapper']} ref={ref}>
            <Image layout={'fill'} src={img || ''} className={s.img} alt={alt} priority={true} />
          </div>
          <div className={`${s.content} ${classNameContent}`}>{children}</div>
          <div className={s['backdrop-wrap']} data-cover-size={size}>
            <div className={s.backdrop} data-cover-size={size} style={{ backgroundImage: `url(${img})` }} />
          </div>
        </Link>
      </ErrorBoundary>
    )
  },
)

export default MainCard
