import React, { FC, HTMLProps, useMemo } from 'react'
import s from './MediaCard.module.sass'
import Link from 'next/link'
import Image from 'next/image'
import { useCurrentSize } from '@shared/lib/helpers/classes'
import { Text } from '@shared/ui/Typography/Text'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Rating } from '@shared/ui/Feedback/Rating'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

// @ts-ignore
import IconSelectionChevronMedium from '@icons/icon-selection-chevron--medium.svg'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

// 1x1, 2x1 for mobile
// type size = 'L1x1 L2x1 M1x1 M2x1 S1x1 1x1 2x1'
interface Props {
  id: string | number
  sizes: string
  title: string
  type?: {
    name: string
    url?: string
  }
  date?: string
  img: {
    desktop: {
      horizontal: string
      vertical: string
    }
    mobile: {
      vertical: string
    }
  }
  url: string
  promo?: boolean
  chevron?: boolean
  className?: string
}

const MediaCard: FC<Props & HTMLProps<HTMLDivElement>> = React.forwardRef(
  ({ id, sizes, title, type, date, img, url = '', promo = false, chevron = false, className = '', ...props }, ref) => {
    const [size] = useCurrentSize(sizes)
    const { isMobile } = useClientResponsive()
    const isLongFormat = size === 'L2x1' || size === 'M2x1' || size === '2x1'

    const isShowType = type.name

    const getPhotoLink = useMemo((): string => {
      if (isMobile) {
        return img.mobile.vertical
      } else {
        return isLongFormat ? img.desktop.horizontal : img.desktop.vertical
      }
    }, [isLongFormat, img])

    return (
      <ErrorBoundary>
        <div data-id={id} className={`${s.card} ${className}`} data-size={size} {...props} ref={ref}>
          <div className={s['image-wrapper']}>
            {promo && <Rating className={s.promo} mode={'fill'} isPromo sizes={'S'} />}

            {!!chevron && (
              <IconSelectionChevronMedium
                className={s['afisha-selection-chevron--medium']}
                width={'29px'}
                height={'50px'}
              />
            )}

            <Link href={url} className={s['card-image-link']} scroll={false}>
              <Image
                layout={'fill'}
                src={getPhotoLink || getPlaceholderImage() || ''}
                alt='card-main'
                className={s['image']}
              />
            </Link>
          </div>

          <div className={s['card-extra']}>
            <>
              {date && (
                <Text className={s['card-date']} sizes={'S'}>
                  {date}
                </Text>
              )}

              {date && isShowType && <span className={s['card-dot']}>·</span>}

              {type.name &&
                (type.url ? (
                  <Link href={type.url} legacyBehavior scroll={false}>
                    <Text className={s['card-media-type']} sizes={'S'}>
                      {type.name}
                    </Text>
                  </Link>
                ) : (
                  <Text className={s['card-media-type']} sizes={'S'}>
                    {type.name}
                  </Text>
                ))}
            </>
          </div>

          <div className={s['card-description']}>
            <Link href={url} scroll={false}>
              <Text sizes={'M M M S'}>{title}</Text>
            </Link>
          </div>
        </div>
      </ErrorBoundary>
    )
  },
)

export default MediaCard
