import s from './Pagination.module.sass'

import { Text } from '@shared/ui/Typography/Text'
import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
// @ts-ignore
import IconArrowLeft from '@icons/icon-arrow-left.svg'
// @ts-ignore
import IconArrowRight from '@icons/icon-arrow-right.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { ButtonLoadMore } from '@shared/ui/Actions/ButtonLoadMore'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { PickAnalytic } from '@shared/api/analytics/types/types'

type Props = {
  mode: 'pages' | 'load-more' | 'all'
  currentPage: number
  pageCount: number
  autoloadingOnMobile?: boolean
  handleSelectPage?: (value: number) => void
  handleLoadMore?: () => void
  analytic: PickAnalytic<'section_name'>
  className?: string
}

const Pagination: FC<Props> = ({
  mode,
  autoloadingOnMobile = false,
  currentPage,
  pageCount,
  handleSelectPage,
  handleLoadMore,
  analytic,
  className = '',
}) => {
  const [loadingLoadMore, setLoadingLoadMore] = useState(false)
  const { isMobile } = useClientResponsive()
  const wrapper = useRef()

  const isShowSectionLoadMore = ['all', 'load-more'].includes(mode) && currentPage < pageCount && pageCount > 1
  const isShowSectionPages = isMobile
    ? !autoloadingOnMobile && mode === 'pages' && pageCount > 1
    : ['all', 'pages'].includes(mode) && pageCount > 1

  useEffect(() => {
    setLoadingLoadMore(false)
  }, [currentPage, pageCount])

  const formattedArrayPages = useMemo(() => {
    const result = []
    const maxElemsNavigation = 9

    if (pageCount <= maxElemsNavigation) {
      return (
        Array(pageCount)
          // @ts-ignore
          .fill()
          .map((_, i) => i + 1)
      )
    }

    result.unshift(1)

    const startForCycleFunc = () => {
      if (currentPage - 3 > 1 && currentPage + 3 < pageCount) return currentPage - 3
      else if (currentPage - 3 <= 1) return 2
      else if (currentPage + 3 >= pageCount) return pageCount - 7
    }

    const startForCycle = startForCycleFunc()
    for (let i = startForCycle; i < startForCycle + 7; i++) {
      result.push(i)
    }

    result.push(pageCount)

    const isShowDotsAfter = result[result.length - 1] - result[result.length - 2] > 1
    const isShowDotsBefore = result[1] - result[0] > 1

    isShowDotsAfter && result.splice(result.length - 2, 1, '...')
    isShowDotsBefore && result.splice(1, 1, '...')

    return result
  }, [pageCount, currentPage])

  const handleSelectInner = (value, type: 'prev' | 'next' | 'number') => {
    if (value !== '...' && value > 0 && value < pageCount + 1) {
      handleSelectPage && handleSelectPage(value)
      switch (type) {
        case 'prev':
          EGG.common.slider_arrow_button_click(null, 'back')
          break
        case 'next':
          EGG.common.slider_arrow_button_click(null, 'forward')
          break
        case 'number':
          EGG.common.page_number_click()
          break
      }
    }
  }

  const handleInnerLoadMore = () => {
    setLoadingLoadMore(true)
    handleLoadMore && handleLoadMore()
  }

  // Загрузка по скроллу (мобилки)
  const callbackObserver = () => {
    if (!isMobile || currentPage === pageCount) return
    // @ts-ignore
    const elementDistance = wrapper.current.getBoundingClientRect().top + window.scrollY
    const currentScroll = window.scrollY + document.documentElement.clientHeight
    if (elementDistance - currentScroll < 200) handleInnerLoadMore()
  }

  useEffect(() => {
    isMobile && document && document.addEventListener('scroll', callbackObserver)
    return () => isMobile && document && document.removeEventListener('scroll', callbackObserver)
  }, [isMobile, currentPage])

  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`} ref={wrapper}>
        {isShowSectionLoadMore && (
          <div className={s['show-more-wrapper']}>
            {autoloadingOnMobile && isMobile ? (
              <LoadingSpinner loading={loadingLoadMore} />
            ) : (
              <ButtonLoadMore
                nextPage={currentPage + 1}
                loading={loadingLoadMore}
                onClick={handleInnerLoadMore}
                analytic={{ section_name: analytic.section_name }}
              />
            )}
          </div>
        )}

        {isShowSectionPages && (
          <div className={s['pagination-wrapper']}>
            <button
              className={s['pagination-prev']}
              onClick={() => handleSelectInner(currentPage - 1, 'prev')}
              type='button'
              disabled={currentPage === 1}>
              <IconArrowLeft />
            </button>

            {formattedArrayPages.map((item, index) => (
              <button
                key={index}
                className={s['pagination-item']}
                onClick={() => handleSelectInner(item, 'number')}
                type='button'
                data-selected={currentPage === item}
                disabled={item === '...'}>
                <Text sizes='M' className={s['pagination-text']}>
                  {item}
                </Text>
              </button>
            ))}

            <button
              className={s['pagination-next']}
              onClick={() => handleSelectInner(currentPage + 1, 'next')}
              type='button'
              disabled={currentPage === pageCount}>
              <IconArrowRight />
            </button>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
export default memo(Pagination)
