import SectionDate from './BookingSectionDate'
import SectionTime from './BookingSectionTime'

import { useRouter } from 'next/router'
import React, { FC, useMemo, useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { generateDatesOptions } from '@widgets/Restaurant/Booking/lib/booking-datetime'

import { selectDate } from '@widgets/Restaurant/Booking/model/createActions'
import { setDatesOptions, setSelectedTime, setOnlineSlotsLoading } from '@widgets/Restaurant/Booking/model/reduce'
import { TypeDay, TypeTime } from '@widgets/Restaurant/Booking/types'

import { API } from '@shared/api'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { EGG } from '@shared/api/analytics'

const BookingDateTime: FC = () => {
  const { isDesktop, isMobile } = useClientResponsive()
  const { restaurantSource, form } = useAppSelector((store) => store.booking)
  const { schedule, dateField, timeField, isErrorsVisible } = form
  const dispatch = useAppDispatch()

  // * --------------- Section. Date --------------- *

  // Текущая дата пользователя
  const todayUserDate = useMemo(() => new Date(), [])

  const [selectedDateTarget, setSelectedDateTarget] = useState<
    null | 'calendar_target' | 'buttons_target' | 'select_target'
  >(null)

  const isDateErrorVisible = isErrorsVisible && !dateField.valid
  const isTimeErrorVisible = isErrorsVisible && !timeField.valid

  const handleChangeDateCalendar = (selectedDateObj: TypeDay | undefined, selectedDate: Date) => {
    EGG.entity.booking.change_reserve_date({
      input: selectedDateObj?.value || selectedDate.toLocaleDateString(),
    })

    if (selectedDateObj) {
      dispatch(selectDate(selectedDateObj))
    } else {
      const newOptionArr = generateDatesOptions(schedule, selectedDate)
      dispatch(setDatesOptions([...dateField.options, ...newOptionArr]))
      dispatch(selectDate(newOptionArr[0]))
    }

    setSelectedDateTarget('calendar_target')
  }

  const handleSetDate = (selectedDate: TypeDay, id: string) => {
    dispatch(selectDate(selectedDate))
    setSelectedDateTarget('buttons_target')

    if (id === 'target_id_0') {
      EGG.entity.booking.click_reserve_date_today()
    } else if (id === 'target_id_1') {
      EGG.entity.booking.click_reserve_date_tomorrow()
    }
  }

  // * --------------- Section. Time --------------- *

  const [selectedTimeTarget, setSelectedTimeTarget] = useState<'buttons_target' | null>(null)

  // * online slots sub-section *
  // @ts-ignore
  const remarked_booking_is_active = restaurantSource.remarked?.active === true || false
  const onlineSlotsLoading = form.onlineSlotsLoading
  const [onlineSlots, setOnlineSlots] = useState(null)
  let timeSlots = null
  if (remarked_booking_is_active) {
    timeSlots = onlineSlots
  } else {
    timeSlots = timeField.options
  }
  const isShowSlotsLabels = remarked_booking_is_active && !onlineSlotsLoading ? !!timeSlots : true

  const [isAllTimeVisible, setIsAllTimeVisible] = useState<boolean>(timeSlots?.length < 3)

  const handleSetTime = (timeString: string) => {
    const selectedTimeObj = timeSlots.find((field: TypeTime | null) => field?.value === timeString) || null
    dispatch(setSelectedTime(selectedTimeObj || undefined))
    setSelectedTimeTarget('buttons_target')

    EGG.entity.booking.click_reserve_time({
      input: timeString,
    })
  }

  const fetchSlots = async (numOfPlaces, date, rest) => {
    try {
      dispatch(setOnlineSlotsLoading(true))
      const response = await API.booking_get_slots({ numOfPlaces, date, rest })
      const responseSlots = response.slots || response
      // const responseSlots = [] // for test placeholder

      const formattedDate = date.split('-').reverse().join('/')
      const formattedSlots =
        responseSlots?.length > 0
          ? responseSlots.map((thisSlot) => {
              const parsedSlotLocale = new Date(thisSlot.start_stamp * 1000).toLocaleTimeString()
              const localeSlotArr = parsedSlotLocale.split(':')
              const itemText = localeSlotArr[0] + ':' + localeSlotArr[1]
              const value = localeSlotArr[0] + ':' + localeSlotArr[1] + ':00'
              return { itemText: itemText, value: value, thisDate: formattedDate }
            })
          : null

      dispatch(setOnlineSlotsLoading(false))

      if (formattedSlots) {
        setOnlineSlots(formattedSlots)
      } else {
        throw new Error('Массив слотов пустой.')
      }
    } catch (error) {
      console.error('Слоты не найдены.', error)
    }
  }

  const router = useRouter()
  const thisPageSlug = router.query.rest_slug // TODO ! убрать данное обращение, добавлено тк где-то теряется restaurantSource?.url_slug

  const fetchOnlineSlotsCallback = () => {
    const targetDate = form.dateField?.selected || form.dateField?.default || null
    const targetRest = restaurantSource?.slug || thisPageSlug || null
    const targetCount = form.guestsCountField?.value || null

    const needToFetchSlots = remarked_booking_is_active && !!targetDate && !!targetRest && !!targetCount
    if (needToFetchSlots) {
      fetchSlots(targetCount, targetDate.value, targetRest)
    }
  }

  const storeBooking = useAppSelector((state) => state.booking)
  const { isOpenModal } = storeBooking

  // Запрашиваем дополнительно слоты с бека для онлайн-бронирования
  useEffect(() => {
    fetchOnlineSlotsCallback()
  }, [form.dateField, form.guestsCountField, restaurantSource, isOpenModal])

  return (
    <ErrorBoundary>
      <SectionDate
        dateField={dateField}
        handleChangeDateCalendar={handleChangeDateCalendar}
        handleSetDate={handleSetDate}
        isDateErrorVisible={isDateErrorVisible}
        schedule={schedule}
        selectedDateTarget={selectedDateTarget}
        todayUserDate={todayUserDate}
        remarked_booking_is_active={remarked_booking_is_active}
      />

      <SectionTime
        handleSetTime={handleSetTime}
        isAllTimeVisible={isAllTimeVisible}
        isDesktop={isDesktop}
        isMobile={isMobile}
        isShowSlotsLabels={isShowSlotsLabels}
        isTimeErrorVisible={isTimeErrorVisible}
        onlineSlotsLoading={onlineSlotsLoading}
        selectedTimeTarget={selectedTimeTarget}
        setIsAllTimeVisible={setIsAllTimeVisible}
        timeField={timeField}
        timeSlots={timeSlots}
      />
    </ErrorBoundary>
  )
}

export default BookingDateTime
