import s from './SubformMenuItem.module.sass'

import React, { FC, useEffect, useState } from 'react'

import { useAppDispatch } from '@app/model/store'

import { getField } from '@pages/OwnerPersonalPage/ui/components/OwnerPostForms/lib/fields'
import { setFieldValid, setFieldValue } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/reducer'
import { TypeFormPostMenuDish } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/types'

import { ImageCropping } from '@widgets/Common/ImageCropping'

import { FieldBase, FieldInput, FieldTextarea } from '@shared/types/fields/fields'
import { BuildTextarea } from '@shared/ui/Form/Build/BuildTextarea'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'
import { BuildInput } from '@shared/ui/Form/Build/BuildInput'
import { useField } from '@shared/lib/fields/hooks/useField/useField'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Title } from '@shared/ui/Typography/Title'
import { Text } from '@shared/ui/Typography/Text'

// @ts-ignore
import IconDelete from '@icons/icon-trash.svg'

interface Props {
  data: TypeFormPostMenuDish
  index: number
  isShowButtonDelete?: boolean
  handleDelete?: (index: number) => void
}

// export const BLOB_URL_DATA_ATTR = 'blobUrl'
// export const FILE_NAME_DATA_ATTR = 'fileName'

const SubformMenuItem: FC<Props> = ({ data, isShowButtonDelete, index, handleDelete }) => {
  const dispatch = useAppDispatch()
  const { title: titleStore, price: priceStore, description: descriptionStore, preview: previewStore } = data

  const [fieldTitle, setFieldTitle] = useField(getField('dish_title', { value: titleStore.value, index }))
  const [fieldPrice, setFieldPrice] = useField(getField('dish_price', { value: priceStore.value, index }))
  const [fieldDescription, setFieldDescription] = useField(
    getField('dish_description', { value: descriptionStore.value, index }),
  )
  const [fieldPhoto, setFieldPhoto] = useState(previewStore.value)

  useEffect(() => {
    // value
    dispatch(setFieldValue({ id: data.id, typeField: 'title', value: fieldTitle.value }))
    dispatch(setFieldValue({ id: data.id, typeField: 'price', value: fieldPrice.value }))
    dispatch(setFieldValue({ id: data.id, typeField: 'description', value: fieldDescription.value }))
    dispatch(setFieldValue({ id: data.id, typeField: 'preview', value: fieldPhoto }))

    // valid
    dispatch(setFieldValid({ id: data.id, typeField: 'title', valid: fieldTitle.valid }))
    dispatch(setFieldValid({ id: data.id, typeField: 'price', valid: fieldPrice.valid }))
    dispatch(setFieldValid({ id: data.id, typeField: 'description', valid: fieldDescription.valid }))
    dispatch(setFieldValid({ id: data.id, typeField: 'preview', valid: !!fieldPhoto }))
  }, [fieldTitle, fieldPrice, fieldDescription, fieldPhoto])

  useEffect(() => {
    setFieldPhoto(() => previewStore.value)
  }, [previewStore.value])

  const handleDeleteDish = () => {
    handleDelete && handleDelete(data.id)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <Title sizes={'h3'}>Блюдо №{index + 1}</Title>

        {isShowButtonDelete && (
          <IconButton id={s.delete} sizes={'M'} mode={'white-border'} icon={IconDelete} onClick={handleDeleteDish} />
        )}
      </div>

      <div className={s['two-columns']}>
        <BuildInput
          field={fieldTitle as FieldInput}
          setField={setFieldTitle as React.Dispatch<React.SetStateAction<FieldBase>>}
        />

        <BuildInput
          field={fieldPrice as FieldInput}
          setField={setFieldPrice as React.Dispatch<React.SetStateAction<FieldBase>>}
        />
      </div>

      <BuildTextarea
        field={fieldDescription as FieldTextarea}
        setField={setFieldDescription as React.Dispatch<React.SetStateAction<FieldBase>>}
      />

      <div>
        <InputTitle nameField={'owner-date-spec'} title={'Обложка'} showRequired={false} />
        <ImageCropping handleSaveImage={setFieldPhoto} img={fieldPhoto} />
        {!fieldPhoto && (
          <Text className={s['photo-description']} sizes='S'>
            Пожалуйста, загружайте фотографии, права на которые принадлежат вам или допускают свободное использование в
            коммерческих целях. Принимаются только jpg и jpeg форматы. Фотографии будут добавлены после модерации.
          </Text>
        )}
      </div>
    </div>
  )
}

export default SubformMenuItem
