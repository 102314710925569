import s from './Avatar.module.sass'

import React, { FC, memo, useState, useEffect } from 'react'
import { useCurrentSize } from '@shared/lib/helpers/classes'
// @ts-ignore
import IconAfisha from '@icons/icon-badge-afisha.svg'

import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Title } from '@shared/ui/Typography/Title'
import { default as ImageNext } from 'next/image'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { AvatarSkeleton } from '@shared/ui/Media/Avatar/index'

// type size = 'S M L XL'

interface Props {
  sizes: string
  src: string
  alt?: string
  afisha?: boolean
  className?: string
  userName: string
}

const randomNum = (min = 0, max) => Math.floor(Math.random() * (max - min + 1)) + min

// Проверка что фотография загрузилась
function getImage(url) {
  return new Promise(function (resolve, reject) {
    // @ts-ignore
    const img = new Image()
    img.onload = () => resolve(url)
    img.onerror = () => reject(url)
    img.src = url
  })
    .then((url) => url)
    .catch((err) => null)
}

const Avatar: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  sizes,
  src,
  afisha = false,
  alt = 'avatar image',
  userName = 'Рик Эстли',
  className = '',
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [avatar, setAvatar] = useState(null)
  const [loading, setLoading] = useState(true)
  const [size] = useCurrentSize(sizes)

  // Проверка что фотография загрузилась
  useEffect(() => {
    getImage(src)
      .then((src) => setAvatar(src))
      .finally(() => setLoading(false))
  }, [])

  // @ts-ignore
  const generatedIdFromName = userName.length > 0 ? userName[randomNum(0, userName.length - 1)]?.charCodeAt() : null

  return (
    <ErrorBoundary>
      {!loading ? (
        <div className={`${s.avatar} ${className}`} data-size={size} data-has-afisha-review={afisha} {...props}>
          {avatar ? (
            <ImageNext layout={'fill'} className={s.img} src={avatar || ''} alt={alt} />
          ) : (
            <div
              className={s['img-default']}
              style={{ backgroundImage: `url(${getPlaceholderImage(generatedIdFromName, 'bg')})` }}>
              <Title sizes={'h5'} className={s['first-letter']}>
                {userName[0]}
              </Title>
            </div>
          )}

          {afisha && <IconAfisha className={s.afisha} />}
        </div>
      ) : (
        <AvatarSkeleton sizes={sizes} className={className} />
      )}
    </ErrorBoundary>
  )
}

export default memo(Avatar)
