import React, { FC, useRef } from 'react'
import s from './EventCard.module.sass'
// @ts-ignore
import IconChevron from '@icons/icon-chevron-medium.svg'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import Image from 'next/image'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'
import { PickAnalytic } from '@shared/api/analytics/types/types'
interface Props {
  data: any
  analytic: PickAnalytic<'section_index' | 'object_id'>
}

const PAYMENT_WIDGET_KEY = '0d8c1d07-209c-497f-9a32-620dd437758a'

const EventCard: FC<Props> = ({ data, analytic }) => {
  const id = data?.input?.concert?.id
  const imageSrc = data?.input?.concert?.image?.url || getPlaceholderImage(id)
  const isAfishaChoice = !!data?.input?.concert?.afishaChoice
  const hasUrl = !!data?.input?.concert?.url

  const element = useRef()

  const handleOpenConcertLink = (thisUrl, ev) => {
    ev.stopPropagation()
    EGG.pages.RestItem.event_card_click(analytic)
    const needToOpenUrl = thisUrl && ev.target.dataset.button !== 'x-open-payment'
    const utm = '?utm_source=restaurants&utm_medium=referral&utm_campaign=afisha_events'
    const target = 'https://afisha.ru' + thisUrl + utm
    if (needToOpenUrl) window.location.href = target
  }

  /**
   * @function handlePaymentAction — работа с window.ticketManager
   * @todo вынести в redux для вызова session с покупками
   * @todo добавить callback, если ticketManager не загрузился,
   *    вместо этого открывать страницу покупки на Афише
   */
  const handleOpenWidget = (data) => {
    EGG.pages.RestItem.event_price_click(analytic)

    const sessionId = data?.input?.sessions[0]?.sessionSourceID
    // @ts-ignore
    sessionId && window.ticketManager.session(PAYMENT_WIDGET_KEY, sessionId)
  }

  useIntersectionAnalytic(element, () => {
    EGG.pages.RestItem.event_card_view(analytic)
  })

  return (
    <ErrorBoundary>
      <div className={s['payment-item']} data-id={id} ref={element}>
        {isAfishaChoice && <IconChevron className={s['payment-item-afisha-selected']} />}

        <div
          className={s['payment-item-wrap']}
          onClick={(ev) => handleOpenConcertLink(hasUrl ? data.input.concert.url : null, ev)}>
          {imageSrc && (
            <Image
              className={s['payment-item-picture']}
              alt={data?.input?.concert?.name || ''}
              src={imageSrc}
              quality={100}
              width={297}
              height={198}
              fill={false}
            />
          )}
        </div>

        {data.amount && (
          <div className={s['payment-item-open-modal-wrap']}>
            <button
              type='button'
              data-button='x-open-payment'
              onClick={(ev) => handleOpenWidget(data)}
              className={s['payment-item-open-modal']}>
              от {data.amount} ₽
            </button>
          </div>
        )}

        {data.name && (
          <h3
            className={s['payment-name']}
            onClick={(ev) => handleOpenConcertLink(hasUrl ? data.input.concert.url : null, ev)}>
            {data.name}
          </h3>
        )}

        {data.type && <span className={s['payment-genre']}>{data.type}</span>}

        {data.timeDescr && <span className={s['payment-time-descr']}>{data.timeDescr}</span>}
      </div>
    </ErrorBoundary>
  )
}

export default EventCard
