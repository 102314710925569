import React, { FC } from 'react'
import s from './ButtonLinkMore.module.sass'
// @ts-ignore
import SliderArrow from '@icons/icon-arrow-slider.svg'
import { Button } from '@shared/ui/Actions/Button'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { PickAnalytic } from '@shared/api/analytics/types/types'

interface Props {
  link: string | object
  handleBeforeAction?: () => boolean
  text?: string
  disabled?: boolean
  className?: string
  analytic?: PickAnalytic<null, 'section_name'>
}
const ButtonLinkMore: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  text = 'Все',
  className = '',
  disabled = false,
  link,
  handleBeforeAction,
  analytic,
  ...props
}) => {
  const router = useRouter()

  const handleClick = () => {
    analytic && EGG.common.button_all_click(analytic)

    if (handleBeforeAction) {
      handleBeforeAction() && router.push(link, link, { scroll: false })
    } else router.push(link, link, { scroll: false })
  }

  return (
    <ErrorBoundary>
      <Link href={link} {...props} onClick={handleClick} scroll={false}>
        <Button sizes='M S' mode='white-shadow' className={`${s.button} ${className}`} disabled={disabled}>
          {text}
          <SliderArrow className={s.icon} />
        </Button>
      </Link>
    </ErrorBoundary>
  )
}

export default ButtonLinkMore
