import React, { FC, memo } from 'react'
import s from './BuildTextareaSend.module.sass'
import { InputTitle } from '@shared/ui/Form/Single/InputTitle'
import { InputHint } from '@shared/ui/Form/Single/InputHint'
import { Textarea } from '@shared/ui/Form/Single/Textarea'
import { useFieldHint } from '@shared/lib/fields/hooks/useFieldHint/useFieldHint'
import { FieldBase, FieldTextarea } from '@shared/types/fields/fields'
import { IconButton } from '@shared/ui/Actions/IconButton'
// @ts-ignore
import IconArrow from '@icons/icon-submit-arrow.svg'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  field: FieldTextarea
  setField: React.Dispatch<React.SetStateAction<FieldBase>>
  // eslint-disable-next-line
  handleChange: (value: string) => void
  // eslint-disable-next-line
  handleSubmit: () => void
  className?: string
}

const BuildTextareaSend: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  field,
  setField,
  handleChange,
  handleSubmit,
  className,
  ...props
}) => {
  const [hint] = useFieldHint(field)

  const setValue = (value: string): void => {
    setField((prev) => ({
      ...prev,
      value: value,
      error: '',
    }))

    handleChange && handleChange(value)
  }

  const classes = {
    disabled: !field.value && field.disabled ? s.disabled : '',
    disabledValue: !!field.value && field.disabled ? s.disabled__value : '',
  }

  return (
    <ErrorBoundary>
      {field.visible && (
        <div className={`${classes.disabled} ${classes.disabledValue} ${className}`} {...props}>
          <InputTitle
            nameField={field.name}
            title={field.settings.label}
            showRequired={field.required && field.settings.showRequired}
          />
          <div className={s.wrapperTextarea}>
            <Textarea field={field} handleValue={setValue} className={s.textarea} />
            <IconButton
              mode='black'
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
              sizes='M'
              icon={IconArrow}
              className={s.button}
              disabled={!field.value.length}
            />
          </div>
          <InputHint text={hint.text} status={hint.status} sizes={'M S'} className={s.error} />
        </div>
      )}
    </ErrorBoundary>
  )
}

export default memo(BuildTextareaSend)
