// @ts-nocheck
import Link from 'next/link'
import React, { FC, useMemo } from 'react'
import s from './MenuLink.module.sass'

import { Text } from '@shared/ui/Typography/Text'
import IconDaily from '@icons/icon-afisha-daily.svg'
import IconDots from '@icons/icon-three-dots.svg'
import IconClose from '@icons/icon--x-big.svg'
import { EGG } from '@shared/api/analytics'

interface Props {
  link?: string
  text?: string
  selected?: boolean
  daily?: boolean
  dots?: boolean
  button?: boolean
  dataId: string | number
  className?: string
}

const MenuLink: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  link,
  text,
  selected,
  daily,
  dots,
  button,
  dataId,
  className,
  ...props
}) => {
  const isLink = Boolean(text && link)
  const isDaily = Boolean(daily && link)
  const isDots = Boolean(dots)
  const isButton = Boolean(button && text)

  const getClasses = useMemo(() => {
    return `${s.link} ${selected ? s.selected : ''} ${isDaily ? s.daily : ''} ${isDots ? s.dots : ''} ${
      className || ''
    }`
  }, [selected, isDaily, isDots, className])

  const handleClick = () => {
    EGG.header.button_tab_click({ navigation_name: isLink ? text : isDaily ? 'Daily' : '' })
  }

  return (
    <>
      {isLink && (
        <Link href={link} className={getClasses} onClick={handleClick} data-id={dataId} scroll={false} {...props}>
          <Text tag='span' sizes='S' uppercase>
            {text}
          </Text>
        </Link>
      )}

      {isDaily && (
        <Link href={link} className={getClasses} onClick={handleClick} data-id={dataId} scroll={false} {...props}>
          <IconDaily className={s['daily-icon']} />
        </Link>
      )}

      {isDots && (
        <button className={getClasses} data-id={dataId} {...props}>
          {selected ? <IconClose /> : <IconDots />}
        </button>
      )}

      {isButton && (
        <button className={getClasses} data-id={dataId} {...props}>
          <Text tag='span' sizes='S' uppercase>
            {text}
          </Text>
        </button>
      )}
    </>
  )
}

export default MenuLink
