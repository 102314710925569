/**
 * @fsd_page SelectionItem - список ресторанов в подборке
 */

import React, { FC, useEffect } from 'react'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { Cover, CoverSkeleton } from '@shared/ui/Layout/Cover'
import s from './SelectionItem.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { CompilationGrid } from '@widgets/News/CompilationGrid'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { numWord, shuffle } from '@widgets/Restaurant/Booking/lib/helper'
import { Rating } from '@shared/ui/Feedback/Rating'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { targetStickersSelection } from '@shared/api/middleware/mappingAPI/patchStickersSelections'
import { SelectionCardCompilation, SelectionCardCompilationSkeleton } from '@widgets/Selection/SelectionCardCompilation'
import SectionTitle from '@shared/ui/Layout/SectionTitle/SectionTitle'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import { EGG } from '@shared/api/analytics'
import { Pagination } from '@features/Common/Pagination'
import { loadMorePage, loadPage } from '@pages/SelectionItem/model/createAsyncThunk'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'

const SelectionItem: FC = () => {
  const dispatch = useAppDispatch()
  const globalState = useAppSelector((state) => state.global)
  const currentCity = globalState.currentCity
  const { selection, recommended, afishaSelected } = useAppSelector((state) => state.pages.selection_item)
  const { loading, restaurants, pagination, data } = selection
  const { isMobile, isTablet } = useClientResponsive()

  const nextPageNumber = pagination?.page.next.number
  const currentPageNumber = pagination?.page.current.number

  const isAllPositionsSold = data?.categories.advert.active
  const restaurantsFormatted =
    isAllPositionsSold && selection?.restaurants?.length > 0 ? shuffle(restaurants) : restaurants
  const imgAlt = data?.description.title
    ? `Фото подборки от Афиши — ${data?.description.title}`
    : `Фото подборки от Афиши`

  let bigCoverImage = isMobile ? data?.img.sizes.desktop.vertical || '' : data?.img.sizes.desktop.horizontal || ''
  const isTargetSlugForStickersSelections =
    data?.url.slug === targetStickersSelection.sel00 ||
    data?.url.slug === targetStickersSelection.sel01 ||
    data?.url.slug === targetStickersSelection.sel02 ||
    data?.url.slug === targetStickersSelection.sel03 ||
    data?.url.slug === targetStickersSelection.sel04
  if (isTargetSlugForStickersSelections && data?.img.icon) {
    bigCoverImage = data?.img.icon
  }

  useEffect(() => {
    EGG.pages.SelectionItem.appear_screen_page()
  }, [selection.data?.id])

  const listRestSkeleton = Array.from(Array(6).keys())

  const isShowRecommendedRests = selection.loading.page || (!!recommended && !selection.loading.page)
  const isShowAfishaSelected = selection.loading.page || (!!afishaSelected && !selection.loading.page)

  return (
    <ErrorBoundary>
      <main>
        {selection.loading.page ? (
          <CoverSkeleton classNameContainer={s.cover} />
        ) : (
          <Cover img={bigCoverImage} classNameContainer={s.cover}>
            {/*// @ts-ignore*/}
            <Cover.Main className={s['cover-main']} alt={imgAlt}>
              {isAllPositionsSold && !isMobile && <Rating isPromo sizes={'M S'} className={s.promo} />}

              {/*{data.date && (*/}
              {/*  <Text tag={'span'} sizes={'M S'} className={s.date}>*/}
              {/*    {data.date}*/}
              {/*  </Text>*/}
              {/*)}*/}

              <Title sizes={'h1 h3'} className={s.title}>
                {data?.description.title}
              </Title>

              <div className={s['cover-additional']}>
                <Text tag={'span'} sizes={'M S'}>
                  Выбор Афиши
                </Text>
                {data.description.restaurantsCount > 0 && (
                  <Text tag={'span'} sizes={'M S'}>
                    {data.description.restaurantsCount}{' '}
                    {numWord(data.description.restaurantsCount, ['ресторан', 'ресторана', 'ресторанов'])}
                  </Text>
                )}
              </div>
              {/*// @ts-ignore*/}
            </Cover.Main>

            {/*// @ts-ignore*/}
            <Cover.Aside alt={imgAlt}>
              {isAllPositionsSold && isMobile && <Rating isPromo sizes={'M S'} className={s.promo} />}
              {/*// @ts-ignore*/}
            </Cover.Aside>
          </Cover>
        )}

        <ContainerTwoColumns>
          {/*// @ts-ignore*/}
          <ContainerTwoColumns.Main>
            {data?.description && (
              <Text sizes={'XL M'} paragraph className={s.description}>
                {data?.description.description}
              </Text>
            )}

            <div className={s['restaurants-list']}>
              {restaurantsFormatted?.length
                ? restaurantsFormatted?.map((rest, index) => (
                    <RestCard
                      key={rest.id}
                      data={rest}
                      sizes={'L L L S'}
                      className={s['restaurants-item']}
                      type={'selection'}
                      analytic={{
                        advertCardPlaceType: 'selection',
                        section_name: `Подборка - ${data?.description.title}`,
                        section_index: index,
                      }}
                    />
                  ))
                : listRestSkeleton.map((el, index) => (
                    <RestCardSkeleton
                      key={index}
                      className={s['restaurants-item']}
                      sizes={'L L L S'}
                      type={'selection'}
                    />
                  ))}
            </div>

            {restaurantsFormatted?.length && (
              <Pagination
                mode={'all'}
                autoloadingOnMobile={true}
                currentPage={currentPageNumber}
                pageCount={pagination?.page.count}
                handleLoadMore={() => dispatch(loadMorePage())}
                // @ts-ignore
                handleSelectPage={(page) => dispatch(loadPage(page))}
                analytic={{ section_name: 'Подборка' }}
                className={s.pagination}
              />
            )}

            {isShowRecommendedRests && (
              <SectionCarousel
                countSlidesOnContainer={3}
                title={'Рекомендации для вас'}
                className={s.recommended}
                skeleton={selection.loading.page}>
                <SectionCarousel.Items>
                  {recommended?.map((rest, index) => (
                    <RestCard
                      key={rest.id}
                      data={rest}
                      sizes={'L L ML M'}
                      type={'list'}
                      analytic={{
                        advertCardPlaceType: null,
                        section_name: `Рекомендации`,
                        section_index: index,
                      }}
                    />
                  ))}
                </SectionCarousel.Items>
                <SectionCarousel.Skeletons>
                  {Array.from(Array(4).keys()).map((i, idx) => (
                    <RestCardSkeleton key={idx} sizes={'L L ML M'} type={'list'} />
                  ))}
                </SectionCarousel.Skeletons>
              </SectionCarousel>
            )}

            <AdvertTemplate type={'100%x240 Footer'} className={s.advert} />
            {/*// @ts-ignore*/}
          </ContainerTwoColumns.Main>

          {/*// @ts-ignore*/}
          <ContainerTwoColumns.Aside>
            {/*@ts-ignore*/}
            {!isMobile && !isTablet && <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />}
            {/*@ts-ignore*/}
            {!isMobile && !isTablet && <AdvertTemplate type={'240x400_2nd'} container={AdvertSidebarSticky} />}
            {/*// @ts-ignore*/}
          </ContainerTwoColumns.Aside>
        </ContainerTwoColumns>

        {isShowAfishaSelected && (
          <ContainerLongLeft classNameContent={s['afisha-selected']}>
            {/* Выбор Афиши */}
            <h2 className='visually-hidden'>Выбор Афиши, app home slider-roll, selection-card</h2>

            {isMobile ? (
              <SectionCarousel
                title={'Выбор Афиши'}
                link={`/${currentCity.slug}/restaurants/afisha_selected/`}
                countSlidesOnContainer={3}
                skeleton={selection.loading.page}>
                <SectionCarousel.Items>
                  {afishaSelected.map((item, index) => (
                    <SelectionCardCompilation
                      key={item.id}
                      data={item}
                      sizes={'M1x1'}
                      analytic={{
                        section_name: `Выбор Афиши`,
                        section_index: index,
                      }}
                    />
                  ))}
                </SectionCarousel.Items>
                <SectionCarousel.Skeletons>
                  {Array.from(Array(6).keys()).map((i, idx) => (
                    <SelectionCardCompilationSkeleton key={idx} sizes={'M1x1'} />
                  ))}
                </SectionCarousel.Skeletons>
              </SectionCarousel>
            ) : (
              <section className={s['home-selections-grid']}>
                <SectionTitle
                  title={'Выбор Афиши'}
                  link={`/${currentCity.slug}/restaurants/afisha_selected/`}
                  skeleton={selection.loading.page}
                />
                <CompilationGrid
                  counts={'6 6 4'}
                  type={'selections'}
                  list={afishaSelected}
                  analytic={{
                    section_name: `Выбор Афиши`,
                  }}
                  skeleton={selection.loading.page}
                />
              </section>
            )}
          </ContainerLongLeft>
        )}

        {!isMobile && !isTablet && (
          // @ts-ignore
          <AdvertTemplate type={'Content1'} container={ContainerLongLeft} className={s.advert} />
        )}
      </main>
    </ErrorBoundary>
  )
}

export default SelectionItem
