import React, { FC } from 'react'
import s from './ButtonLoadMore.module.sass'
import { Button } from '@shared/ui/Actions/Button'
// @ts-ignore
import IconLoading from '@icons/icon-booking-loading.svg'
import LoadingSpinner from '../../Feedback/LoadingSpinner/LoadingSpinner'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { PickAnalytic } from '@shared/api/analytics/types/types'

interface Props {
  nextPage: number
  loading?: boolean
  className?: string
  onClick: () => void
  analytic?: PickAnalytic<null, 'section_name'>
}

const ButtonLoadMore: FC<Props> = ({ nextPage, loading = false, onClick, analytic, className = '' }) => {
  const handleClick = () => {
    analytic && EGG.common.button_load_more_click(analytic)
    onClick()
  }

  return nextPage ? (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`}>
        <LoadingSpinner loading={loading} />

        <Button
          sizes={'XL'}
          mode={'white-border'}
          // @ts-ignore
          onClick={handleClick}
          className={s['show-more-button']}
          data-visible={!loading}>
          Показать ещё
        </Button>
      </div>
    </ErrorBoundary>
  ) : (
    <></>
  )
}

export default ButtonLoadMore
