import React, { FC } from 'react'
import s from './PriceRange.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  priceRange: number
  colorMode: string
  className?: string
  link?: string
}

const PriceRange: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  priceRange,
  colorMode = 'black',
  className = '',
  link,
  ...props
}) => {
  const classes = (...args: Array<string>) =>
    args
      .map((classNameArg: string) => !!classNameArg && s[classNameArg])
      .join(' ')
      .trim()

  const getRootClassName = () => {
    let output = classes('price-range-wrap')
    if (colorMode === 'white') {
      output += ' ' + classes('price-range-wrap--white')
    } else if (colorMode === 'black') {
      output += ' ' + classes('price-range-wrap--black')
    }
    return output
  }

  return (
    <ErrorBoundary>
      <span className={`${getRootClassName()} ${link ? s.link : ''} ${className}`} {...props}>
        {Array(4)
          .fill(0)
          .map((el, index) => (
            <Text tag={'span'} sizes={'S'} key={index} data-active={priceRange >= index + 1} uppercase>
              ₽
            </Text>
          ))}
      </span>
    </ErrorBoundary>
  )
}

export default PriceRange
