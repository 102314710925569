import React, { FC, memo } from 'react'
import s from './Search.module.sass'
import { Input } from '@shared/ui/Form/Single/Input'
// import { useFieldHint } from '@shared/lib/fields/hooks/useFieldHint'
import { FieldBase, FieldInput } from '@shared/types/fields/fields'
// @ts-ignore
import IconSearch from '@icons/icon-search.svg'
// @ts-ignore
import IconClean from '@icons/icon--x.svg'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  field: FieldInput
  setField: React.Dispatch<React.SetStateAction<FieldBase>>
  // eslint-disable-next-line
  handleChange: (value: string) => void
  sizes?: ''
  className?: string
  tabIndex?: number
}

// TODO: ADD SIZES FOR DESKTOP SEARCH AND POPUP (EXAMPLE: S AND M)
const Search: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  field,
  setField,
  handleChange,
  className = '',
  tabIndex = 1,
  ...props
}) => {
  const setValue = (value: string): void => {
    setField((prev) => ({
      ...prev,
      value: value,
      error: '',
    }))

    handleChange && handleChange(value)
  }

  const handleClean = () => {
    setValue('')
  }

  const classes = {
    disabled: !field.value && field.disabled ? s.disabled : '',
    disabledValue: !!field.value && field.disabled ? s.disabled__value : '',
  }

  return (
    <ErrorBoundary>
      {field.visible && (
        <div className={`${s.search} ${classes.disabled} ${classes.disabledValue} ${className}`} {...props}>
          <IconSearch className={s['icon-search']} />
          <Input field={field} handleValue={setValue} className={s.input} tabIndex={tabIndex}/>
          <button className={`${s.clean} ${field.value ? s.visible : ''}`} onClick={handleClean}>
            <IconClean />
          </button>
        </div>
      )}
    </ErrorBoundary>
  )
}

export default memo(Search)
