/**
 * @fsd_page AppHome - главная
 */

import s from './AppHome.module.sass'
import React, { useEffect, useMemo } from 'react'
import { useAppSelector } from '@app/model/store'
import { TagsRoll } from '@pages/AppHome/ui/TagsRoll'
import { CompilationGrid } from '@widgets/News/CompilationGrid'
import { NewsCard, NewsCardSkeleton } from '@widgets/News/NewsCard'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { PromoRoll } from '@features/PromoRoll'
import { ContainerLong } from '@shared/ui/Layout/Containers/ContainerLong'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { SectionCarousel } from '@shared/ui/Layout/SectionCarousel'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
// @ts-ignore
import IconArrow from '@icons/icon-arrow-cover.svg'
import SectionTitle from '@shared/ui/Layout/SectionTitle/SectionTitle'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { RestaurantFilters } from '@features/Restaurant/RestaurantFilters'
import { useRouter } from 'next/router'
import { NewsCardCover } from '@widgets/News/NewsCardCover'
import { SelectionCardCompilation, SelectionCardCompilationSkeleton } from '@widgets/Selection/SelectionCardCompilation'
import { TypeOutput } from '@features/Restaurant/RestaurantFilters/model/types'
import { EGG } from '@shared/api/analytics'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import RestaurantQuickFilter from '@widgets/Restaurant/RestaurantQuickFilter/RestaurantQuickFilter'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { RestaurantMainCards } from '@widgets/Restaurant/RestaurantMainCards'
import { DailyCarousel } from '@widgets/Common/Daily/DailyCarousel'

export default function AppHome(props) {
  const router = useRouter()
  const { isMobile, isTablet } = useClientResponsive()
  const stateGlobal = useAppSelector((state) => state.global)
  const stateHome = useAppSelector((state) => state.pages.app_home)
  const { currentCity } = stateGlobal
  const { featureRests, tags, specialRests, ratingRests, afishaSelections, weekPlans, restaurantsNews, otherNews } =
    stateHome.sections

  const tagsFormatted = useMemo(() => {
    if (!tags.list?.length) return

    // @ts-ignore
    return tags.list.map((tag) => ({
      id: tag.tag_object.id,
      name: tag.tag_object.name,
      slug: tag.tag_object.slug,
    }))
  }, [tags.list])

  const handleFilterData = (data: TypeOutput) => {
    router.push(
      `/${router.query.city_slug}/restaurants/restaurant_list/${data.urlQuery}`,
      `/${router.query.city_slug}/restaurants/restaurant_list/${data.urlQuery}`,
      { scroll: false },
    )
  }

  useEffect(() => {
    EGG.pages.AppHome.appear_screen_page()
  }, [stateGlobal.currentCity?.id])

  const activeMainCardsSkeleton = featureRests.loading
  const activeTagsRollSkeleton = tags.loading

  const showMainSection =
    activeMainCardsSkeleton || activeTagsRollSkeleton || !!featureRests.list?.length || !!tags.list?.length
  const isShowSectionSpecialRests = specialRests.loading || (!specialRests.loading && !!specialRests.list?.length)
  const isShowSectionNewsInteresting =
    restaurantsNews.loading || (!restaurantsNews.loading && !!restaurantsNews.list?.length)
  const isShowSectionRecommended = ratingRests.loading || (!ratingRests.loading && !!ratingRests.list?.length)
  const isShowAllNews = otherNews.loading || (!otherNews.loading && !!otherNews.list?.length)
  const isShowAfishaSelected =
    afishaSelections.loading || (!afishaSelections.loading && !!afishaSelections.list?.length)
  const isShowWeekPlans = weekPlans.loading || (!weekPlans.loading && !!weekPlans?.data)

  return (
    <ErrorBoundary>
      <main>
        <h1 className='visually-hidden'>
          {props?.seo_read?.title ? props.seo_read.title : `Рестораны ${currentCity?.genitive_case}`}
        </h1>

        {showMainSection && (
          <div className={s['main-section']}>
            <RestaurantMainCards list={featureRests.list} skeleton={activeMainCardsSkeleton} />

            <ContainerLong classNameContent={s['cover-roll-content']}>
              <TagsRoll tags={tagsFormatted} skeleton={activeTagsRollSkeleton} />
            </ContainerLong>
          </div>
        )}

        <div className={s['container-main-wrapper']}>
          {/* Маркетинговые блоки */}
          <PromoRoll />

          <ContainerLongLeft>
            <SectionTitle
              title={`Все кафе и рестораны ${stateGlobal.cityRead?.genitive_case}`}
              link={`/${stateGlobal.cityRead?.slug}/restaurants/restaurant_list/`}
              className={s['quick-filter-title']}
              skeleton={!stateGlobal.cityRead}
            />
            <RestaurantFilters preloadingResults={false} selected={null} handleOutputData={handleFilterData} />
            <RestaurantQuickFilter className={s['quick-filter']} />
          </ContainerLongLeft>

          <ContainerTwoColumns>
            {/*// @ts-ignore*/}
            <ContainerTwoColumns.Main className={s['container-main']}>
              {/* Спецпредложения, (рестораны) */}

              {isShowSectionSpecialRests && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Спецпредложения'}
                  link={`/${currentCity?.slug}/restaurants/restaurant_list/owner_offer/`}
                  className={s['special-rests-slider-roll']}
                  skeleton={specialRests.loading}>
                  <SectionCarousel.Items>
                    {specialRests.list?.map((item, index) => (
                      <RestCard
                        key={item.id}
                        data={item}
                        sizes={'L ML'}
                        type={'special-offer'}
                        analytic={{
                          advertCardPlaceType: null,
                          section_name: `Спецпредложения`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <RestCardSkeleton key={idx} sizes={'L ML'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              {/* Самое интересное про рестораны, (новости) */}
              {isShowSectionNewsInteresting && (
                <SectionCarousel
                  countSlidesOnContainer={2}
                  title={'Самое интересное про рестораны'}
                  link={`/${currentCity?.slug}/restaurant-news/`}
                  skeleton={restaurantsNews.loading}>
                  <SectionCarousel.Items>
                    {restaurantsNews.list?.map((news, index) => (
                      <NewsCard
                        sizes={(index + 1) % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                        key={news.id}
                        data={news}
                        analytic={{
                          section_name: `Самое интересное про рестораны`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <NewsCardSkeleton
                        key={idx}
                        sizes={(idx + 1) % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                      />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              {/* Рекомендации для вас, (рестораны) */}
              {isShowSectionRecommended && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Рекомендации для вас'}
                  className={s['by-rating-rests-slider-roll']}
                  skeleton={ratingRests.loading}>
                  <SectionCarousel.Items>
                    {ratingRests.list?.map((item, index) => (
                      <RestCard
                        key={item.id}
                        data={item}
                        sizes={'L ML'}
                        type={'list'}
                        analytic={{
                          advertCardPlaceType: null,
                          section_name: `Рекомендации`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <RestCardSkeleton key={idx} sizes={'L ML'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}

              <AdvertTemplate type={'100%x240 Listing1'} />

              {/* Статьи, новости, обзоры (новости) */}
              {isShowAllNews && (
                <SectionCarousel
                  countSlidesOnContainer={3}
                  title={'Статьи, новости, обзоры'}
                  link={`/${currentCity?.slug}/restaurant-news/`}
                  skeleton={otherNews.loading}>
                  <SectionCarousel.Items>
                    {otherNews.list?.map((news, index) => (
                      <NewsCard
                        sizes={index % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'}
                        data={news}
                        key={news.id}
                        analytic={{
                          section_name: `Статьи, новости, обзоры`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <NewsCardSkeleton key={idx} sizes={idx % 3 === 0 ? 'L2x1 M2x1 M2x1 1x1' : 'L1x1 M1x1 M1x1 1x1'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              )}
              {/*// @ts-ignore*/}
            </ContainerTwoColumns.Main>

            {!isMobile && !isTablet && (
              //@ts-ignore
              <ContainerTwoColumns.Aside>
                {/*@ts-ignore*/}
                {!isMobile && !isTablet && <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />}
                {/*@ts-ignore*/}
                {!isMobile && !isTablet && <AdvertTemplate type={'240x400_2nd'} container={AdvertSidebarSticky} />}
                {/*// @ts-ignore*/}
              </ContainerTwoColumns.Aside>
            )}
          </ContainerTwoColumns>

          {/* Планы на неделю */}
          {isShowWeekPlans && (
            <ContainerLongLeft>
              <NewsCardCover
                data={weekPlans.data}
                subtitles={['5 минут на чтение']}
                analytic={{
                  section_index: 0,
                  section_name: `Планы на неделю`,
                }}
                skeleton={weekPlans.loading}
              />
            </ContainerLongLeft>
          )}

          {/*@ts-ignore*/}
          <AdvertTemplate type={'Parallax Footer'} container={ContainerLongLeft} />

          {/* Выбор Афиши */}
          {isShowAfishaSelected && (
            <ContainerLongLeft>
              {isMobile ? (
                <SectionCarousel
                  title={'Выбор Афиши'}
                  link={`/${currentCity?.slug}/restaurants/afisha_selected/`}
                  countSlidesOnContainer={3}
                  skeleton={afishaSelections.loading}>
                  <SectionCarousel.Items>
                    {afishaSelections.list?.map((item, index) => (
                      <SelectionCardCompilation
                        key={item.id}
                        data={item}
                        sizes={'M1x1'}
                        analytic={{
                          section_name: `Выбор Афиши`,
                          section_index: index,
                        }}
                      />
                    ))}
                  </SectionCarousel.Items>
                  <SectionCarousel.Skeletons>
                    {Array.from(Array(6).keys()).map((i, idx) => (
                      <SelectionCardCompilationSkeleton key={idx} sizes={'M1x1'} />
                    ))}
                  </SectionCarousel.Skeletons>
                </SectionCarousel>
              ) : (
                <section className={s['home-selections-grid']}>
                  <SectionTitle
                    title={'Выбор Афиши'}
                    link={`/${currentCity?.slug}/restaurants/afisha_selected/`}
                    skeleton={afishaSelections.loading}
                  />
                  <CompilationGrid
                    type={'selections'}
                    counts={'6 6 4'}
                    list={afishaSelections.list}
                    analytic={{ section_name: `Выбор Афиши` }}
                    skeleton={afishaSelections.loading}
                  />
                </section>
              )}
            </ContainerLongLeft>
          )}

          {/*@ts-ignore*/}
          <DailyCarousel container={ContainerLongLeft} />

          {/*@ts-ignore*/}
          {!isMobile && <AdvertTemplate type={'Context_full'} container={ContainerLongLeft} />}
        </div>
      </main>
    </ErrorBoundary>
  )
}
