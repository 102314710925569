import React, { FC, memo } from 'react'
import s from './Checkbox.module.sass'
import { CheckboxOption } from '@shared/types/fields/fields'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'
// @ts-ignore
import IconCheckboxCheckmark from '@icons/icon-checkbox-checkmark.svg'
import { Text } from '@shared/ui/Typography/Text'

interface Props {
  option: CheckboxOption
  checked: boolean
  // eslint-disable-next-line
  onChange: (value: CheckboxOption, checked: boolean) => void
  mode?: 'checkbox' | 'button'
  className?: string
}

const Checkbox: FC<Props> = ({ option, checked, onChange, mode = 'checkbox', className = '' }) => {
  // eslint-disable-next-line
  const handleChangeInner = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(option, !checked)
  }

  return (
    <ErrorBoundary>
      <label
        className={`${s.button} ${className}`}
        data-without-label={option.label ? false : true}
        data-disabled={option.disabled || false}
        data-checked={checked}
        data-mode={mode}>
        <input
          type='checkbox'
          id={String(option.id)}
          name={option.name}
          value={option.value}
          disabled={option.disabled}
          checked={checked}
          onChange={handleChangeInner}
          hidden
        />

        {mode === 'checkbox' && (
          <>
            <div className={s.checkmark}>
              <IconCheckboxCheckmark />
            </div>

            {option?.label && (
              <Text sizes={'M'} className={s.title}>
                {option.label}
              </Text>
            )}
          </>
        )}

        {mode === 'button' && option?.label && <span>{option.label}</span>}
      </label>
    </ErrorBoundary>
  )
}

export default memo(Checkbox)
