import React, { FC, memo, ReactNode, useMemo } from 'react'
import s from './AfishaTag.module.sass'
import { Button } from '@shared/ui/Actions/Button'
// @ts-ignore
import IconChevron from '@icons/icon-chevron-medium.svg'
import { ErrorBoundary } from '../../../../../lib/components/ErrorBoundary'

interface Props {
  onClick: () => void
  className?: string
  active?: boolean
  children?: ReactNode
}

const AfishaTag: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  onClick,
  className = '',
  active = false,
  children,
  ...props
}) => {
  return (
    <ErrorBoundary>
      <Button
        sizes='M'
        mode='white-border'
        className={`${s.button} ${className}`}
        onClick={onClick}
        data-active={active}
        {...props}>
        <IconChevron className={s.icon} />
        {children || 'Выбор Афиши'}
      </Button>
    </ErrorBoundary>
  )
}

export default memo(AfishaTag)
