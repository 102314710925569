import React, { FC, memo, ReactNode, useEffect, useState } from 'react'
import s from './RestFavorite.module.sass'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { API } from '@shared/api'
import { addFavoriteObject, deleteFavoriteObject } from '@app/model/reduce'
import { API_GRAPH } from '@shared/api/api.gql'

interface Props {
  restId: number
  children: ReactNode
  analytic: {
    data: TypeMappingRestOutput
    other: PickAnalytic<'section_name'>
  }
}

const RestFavorite: FC<Props> = ({ restId, analytic, children }) => {
  const dispatch = useAppDispatch()
  const stateUser = useAppSelector((state) => state.global.user.data)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    stateUser && setIsActive(restId in stateUser.favorites_objects.restaurants)
  }, [restId, stateUser])

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const analyticMethod = isActive
      ? EGG.entity.restaurant.click_remove_favorite
      : EGG.entity.restaurant.click_add_favorite

    const requestMethod = isActive ? API_GRAPH.deleteFavorite : API_GRAPH.addFavorite

    middlewareAuthorized(() => {
      const actionMethod = isActive ? deleteFavoriteObject : addFavoriteObject
      const revertActionMethod = isActive ? addFavoriteObject : deleteFavoriteObject
      dispatch(actionMethod({ object: 'restaurants', id: restId }))

      requestMethod
        .load({
          subjectId: restId,
          subjectTypeName: 'Restaurant',
        })
        .then((res) => {
          analyticMethod(analytic.data, analytic.other)
        })
        .catch(() => {
          dispatch(revertActionMethod({ object: 'restaurants', id: restId }))
        })
    })
  }

  return (
    <ErrorBoundary>
      <div className={s.button} onClick={handleClick} data-active={isActive}>
        {children}
      </div>
    </ErrorBoundary>
  )
}

export default memo(RestFavorite)
