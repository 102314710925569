import React, { FC, useRef } from 'react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MediaCardCompilation } from '@shared/ui/Layout/Cards/MediaCardCompilation'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { useAppSelector } from '@app/model/store'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { EGG } from '@shared/api/analytics'
import { PickAnalytic } from '@shared/api/analytics/types/types'

// D-desktop - M-mobile
// type size = 'D1x1 D2x1 M1x1'
interface Props {
  data: TypeMappingSelectionOutput
  sizes: string
  className?: string
  analytic: PickAnalytic<'section_index' | 'section_name'>
}

const SelectionCardCompilation: FC<Props> = ({ data, sizes, analytic, className = '', ...props }) => {
  const stateGlobal = useAppSelector((state) => state.global)
  const citySlug = stateGlobal.currentCity.slug
  const cardRef = useRef()

  const handleClick = () => EGG.entity.selection.card_click(data, analytic)
  useIntersectionAnalytic(cardRef, () => EGG.entity.selection.card_view(data, analytic))

  return (
    <ErrorBoundary>
      <MediaCardCompilation
        id={String(data.id)}
        title={data.description.title}
        sizes={sizes}
        img={{
          horizontal: data.img.sizes.desktop.horizontal,
          vertical: data.img.sizes.desktop.vertical,
        }}
        link={`/${citySlug}/restaurants/${data.url.slug}`}
        chevron={data.categories.selection}
        promo={data.categories.advert.active}
        className={className}
        onClick={handleClick}
        ref={cardRef}
        {...props}
      />
    </ErrorBoundary>
  )
}

export default SelectionCardCompilation
