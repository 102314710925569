import React, { FC, useRef } from 'react'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MediaCard } from '@shared/ui/Layout/Cards/MediaCard'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { useAppSelector } from '@app/model/store'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'

// 1x1, 2x1 for mobile
// type size = 'L1x1 L2x1 M1x1 M2x1 S1x1 1x1 2x1'
interface Props {
  data: TypeMappingSelectionOutput
  className?: string
  sizes: string
  analytic: PickAnalytic<'section_index' | 'section_name'>
}

const SelectionCard: FC<Props> = ({ data, analytic, className = '', sizes }) => {
  const stateGlobal = useAppSelector((state) => state.global)
  const citySlug = stateGlobal.currentCity.slug
  const cardRef = useRef()

  const handleClick = () => EGG.entity.selection.card_click(data, analytic)
  useIntersectionAnalytic(cardRef, () => EGG.entity.selection.card_view(data, analytic))

  return (
    <ErrorBoundary>
      <MediaCard
        id={String(data.id)}
        sizes={sizes}
        title={data.description.title}
        // @ts-ignore
        type={{ name: 'Выбор афиши', url: `/${citySlug}/restaurants/afisha_selected/` }}
        img={{
          desktop: {
            horizontal: data.img.sizes.desktop.horizontal,
            vertical: data.img.sizes.desktop.vertical,
          },
          mobile: {
            vertical: data.img.sizes.mobile.vertical,
          },
        }}
        url={`/${citySlug}/restaurants/${data.url.slug}`}
        promo={data.categories.advert.active}
        chevron={data.categories.selection}
        className={className}
        ref={cardRef}
        onClick={handleClick}
      />
    </ErrorBoundary>
  )
}

export default SelectionCard
