import React, { FC, useRef } from 'react'
import s from './SelectionCardCover.module.sass'
import { useRouter } from 'next/router'

import generateNewsTitle from '@shared/lib/helpers/newsTitle'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { useAppSelector } from '@app/model/store'
import { MediaCardCover } from '@shared/ui/Layout/Cards/MediaCardCover'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { NewsCardCoverSkeleton } from '@widgets/News/NewsCardCover'
import { SelectionCardCoverSkeleton } from '@widgets/Selection/SelectionCardCover/index'

interface Props {
  data: TypeMappingSelectionOutput
  analytic: PickAnalytic<'section_index' | 'section_name'>
  subtitles?: string[]
  buttonText?: string
  skeleton?: boolean
}

const SelectionCardCoverWrapper: FC<Props> = (props) => {
  const { skeleton, ...data } = props
  return skeleton ? <SelectionCardCoverSkeleton /> : <SelectionCardCover {...data} />
}

const SelectionCardCover: FC<Props> = ({ data, analytic, subtitles, buttonText }, ref) => {
  const stateGlobal = useAppSelector((state) => state.global)
  const router = useRouter()
  const cardRef = useRef()

  useIntersectionAnalytic(cardRef, () => EGG.entity.selection.card_view(data, analytic))

  const handleOpenNews = () => {
    router.push(
      `/${stateGlobal.currentCity.slug}/restaurants/${data.url.slug}`,
      `/${stateGlobal.currentCity.slug}/restaurants/${data.url.slug}`,
      { scroll: false },
    )
    EGG.entity.selection.card_click(data, analytic)
  }

  // const placeholderImage = null
  // const thisImage = data?.cover?.cover1?.replace('//develop.rests', '//rests') || placeholderImage

  return (
    <ErrorBoundary>
      <MediaCardCover
        title={generateNewsTitle(data.description.title)}
        img={{ desktop: data.img.sizes.desktop.horizontal, mobile: data.img.sizes.mobile.horizontal }}
        subtitles={subtitles || ['Планы на неделю', '5 минут на чтение']}
        handleClick={handleOpenNews}
        buttonText={buttonText || 'Читать подборку'}
        ref={cardRef}
      />
    </ErrorBoundary>
  )
}

export default SelectionCardCoverWrapper
