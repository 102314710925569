import React, { FC, ReactNode, useContext, useEffect, useMemo, useRef } from 'react'
import { DropdownContext } from '@shared/ui/Form/Single/Filters/Dropdown/Context'
import s from '@shared/ui/Form/Single/Filters/Dropdown/Dropdown.module.sass'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { Button } from '@shared/ui/Actions/Button'
// @ts-ignore
import IconClose from '@icons/icon--x.svg'
// @ts-ignore
import IconArrow from '@icons/icon-arrow-small.svg'
import { Text } from '@shared/ui/Typography/Text'

interface Props {
  children: ReactNode
  count: number | null
  className?: string
  handleRemove?: () => void
  handleOpen?: () => void
  handleClose?: () => void
}

const DropdownButton: FC<Props & React.ButtonHTMLAttributes<any>> = ({
  count = null,
  children,
  className = '',
  handleOpen,
  handleClose,
  handleRemove,
  ...props
}) => {
  // @ts-ignore
  const [context, setContext] = useContext(DropdownContext)
  const refButton = useRef()

  const handleOpenPopup = () => {
    if (context.opened) {
      handleClose ? handleClose() : setContext((prev) => ({ ...prev, opened: false }))
    } else {
      handleOpen ? handleOpen() : setContext((prev) => ({ ...prev, opened: true }))
    }
  }

  useEffect(() => {
    if (refButton.current && !context.floatingRelative)
      setContext((prev) => ({ ...prev, floatingRelative: refButton.current }))
  }, [])

  const handleRemoveInner = (e) => {
    e.stopPropagation()
    handleRemove && handleRemove()
  }

  return (
    <ErrorBoundary>
      <div ref={refButton} className={className} {...props}>
        <Button
          sizes='M'
          mode='white-border'
          className={s.button}
          data-active={!!count}
          // eslint-disable-next-line
          onClick={handleOpenPopup}
          {...props}>
          <span className={s.button__icon}>
            {count ? (
              <IconClose className={s.button__remove} onClick={handleRemoveInner} />
            ) : (
              <IconArrow className={s.button__arrow} data-opened={context.opened} />
            )}
          </span>
          {children}
          {!!count && (
            <Text sizes={'S'} className={s.button__count}>
              {count}
            </Text>
          )}
        </Button>
      </div>
    </ErrorBoundary>
  )
}

export default DropdownButton
