import s from './RestaurantMainCard.module.sass'
import React, { FC, useRef } from 'react'

import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Rating } from '@shared/ui/Feedback/Rating'
import { openBooking } from '@widgets/Restaurant/Booking'
import { useAppDispatch } from '@app/model/store'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import {
  sendAdvertisingRestAnalytic,
  TypeAdvertisingCardRestEvent,
} from '@shared/api/analytics/advertising/advertising'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { MainCard, MainCardSkeleton } from '@shared/ui/Layout/Cards/MainCard'
import { EGG } from '@shared/api/analytics'
import { PickAnalytic } from '@shared/api/analytics/types/types'
import { PromoBadgeCTA } from '@shared/ui/Feedback/PromoBadges/PromoBadgeCTA'

interface Props {
  data: TypeMappingRestOutput
  analytic?: PickAnalytic<null, 'section_name' | 'section_index' | 'section_type'>
}

const RestaurantMainCard: FC<Props> = ({ data, analytic }) => {
  const { id, name, description, feedback, booking, images, schedule, url, discounts } = data
  const thisCoverImage = images.cover.rest1064x1064 || images.main
  const dispatch = useAppDispatch()
  const wrapperRef = useRef(null)
  const alt = `Фото ресторана: ${data.name}`
  const handleBooking = (e) => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(openBooking(data))
    EGG.entity.restaurant.click_booking(data, { section_name: 'feature', section_type: 'main_slider', ...analytic })
  }

  const handleAnalyticClickHouse = (typeEvent: 'click' | 'visible') => {
    const EGGMethod = typeEvent === 'visible' ? EGG.entity.restaurant.card_view : EGG.entity.restaurant.card_click

    EGGMethod(data, { section_name: 'feature', ...analytic })
  }

  const handleAnalyticAdvert = (typeEvent: TypeAdvertisingCardRestEvent) => {
    sendAdvertisingRestAnalytic({
      type: typeEvent,
      city_id: data.address.city.id,
      rest_id: id,
      variety: 'feature',
    })
  }

  useIntersectionAnalytic(wrapperRef, () => {
    handleAnalyticAdvert('visible')
    handleAnalyticClickHouse('visible')
  })

  const handleClickAnalytic = () => {
    handleAnalyticAdvert('click')
    handleAnalyticClickHouse('click')
  }

  return (
    <ErrorBoundary>
      <MainCard img={thisCoverImage} alt={alt} href={url.path} onClick={handleClickAnalytic} classNameContent={s.card}>
        {feedback.rating >= 4 && <Rating rating={feedback.rating} className={s.rating} />}

        <div className={s.content} ref={wrapperRef}>
          <Text sizes={'M S'} uppercase>
            Ресторан
          </Text>

          {name && (
            <Title tag='h2' sizes='h2 h3' className={s['rest-title']}>
              {name}
            </Title>
          )}

          {description.title && (
            <Text sizes={'M S'} className={s['rest-description']}>
              {description.title}
            </Text>
          )}
        </div>

        {booking.afisha.active && (
          <div className={s['buttons-bottom']}>
            <Button
              sizes={'XL M'}
              data-temp-rest-id={id}
              mode={'white'}
              className={s['booking-button']}
              onClick={handleBooking}>
              Забронировать
            </Button>
            {discounts.remarked && (
              <PromoBadgeCTA sizes={'XL none'} className={s['promo-badge']}>
                -{discounts.remarked}₽
              </PromoBadgeCTA>
            )}
          </div>
        )}

        {false && (
          <button className={s['like-button']} data-temp-rest-id={id} type='button'>
            like this rest
          </button>
        )}
      </MainCard>
    </ErrorBoundary>
  )
}

export default RestaurantMainCard
