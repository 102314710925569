import React, { FC } from 'react'
import s from './LoadingSpinner.module.sass'
// @ts-ignore
import IconLoading from '@icons/icon-booking-loading.svg'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

interface Props {
  loading: boolean
  className?: string
  size?: number
}

const LoadingSpinner: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  loading,
  size,
  className = '',
  ...props
}) => {
  return (
    <ErrorBoundary>
      <div className={`${s.wrapper} ${className}`} data-visible={loading} {...props}>
        <IconLoading className={s.spinner} style={size && { width: `${size}px`, height: `${size}px` }} />
      </div>
    </ErrorBoundary>
  )
}

export default LoadingSpinner
