import s from './BookingScreenForm.module.sass'

import React, { useEffect, useCallback, FC } from 'react'

import l10n from '../../../lib/booking-localization'
import { getGuestCountString } from '../../../lib/helper'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { BookingButton } from '@widgets/Restaurant/Booking/ui/components/BookingButton'
import { BookingComment } from '@widgets/Restaurant/Booking/ui/components/BookingComment'
import { BookingDateTime } from '@widgets/Restaurant/Booking/ui/components/BookingDateTime'
import { BookingFullName } from '@widgets/Restaurant/Booking/ui/components/BookingFullName'
import { BookingGuests } from '@widgets/Restaurant/Booking/ui/components/BookingGuests'
import { BookingPhone } from '@widgets/Restaurant/Booking/ui/components/BookingPhone'
import { closeBooking } from '@widgets/Restaurant/Booking'
import { submitBooking } from '@widgets/Restaurant/Booking/model/createActions'

import { BookingAlerts } from '@widgets/Restaurant/Booking/ui/components/BookingAlerts'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

// @ts-ignore
import IconClose from '@icons/icon--x-thin.svg'
import { EGG } from '@shared/api/analytics'

declare const window: any

const BookingScreenForm: FC = () => {
  const { isDesktop } = useClientResponsive()

  const dispatch = useAppDispatch()
  const storeBooking = useAppSelector((state) => state.booking)
  const { isOpenScreenConfirm, restaurantSource } = storeBooking
  const { dateField, timeField, guestsCountField, phoneField, onlineSlotsLoading, checkDiscountLoading } =
    storeBooking.form
  const bookingCheckoutId = null // TODO: AREST-5043, нужно отправлять id бронирования в любом формате

  const handleAbortBookingCallback = useCallback(() => {
    !isOpenScreenConfirm && dispatch(closeBooking(null))
    EGG.entity.booking.click_reserve_close()
  }, [dispatch, isOpenScreenConfirm, restaurantSource.id, restaurantSource.name])

  // rcm rec-sys event
  useEffect(() => {
    // TODO: useRCM('bookingCheckout', { itemId: 'Restaurant_' + restaurantSource.id, checkoutId: bookingCheckoutId, })
    window.rcm &&
      window.rcm('bookingCheckout', {
        itemId: 'Restaurant_' + restaurantSource.id,
        checkoutId: bookingCheckoutId,
      })
  }, [restaurantSource.name, restaurantSource.id])

  // egg click-house event
  useEffect(() => {
    EGG.entity.booking.appear_screen_reserve()
  }, [restaurantSource.name, restaurantSource.id])

  const handleClickSubmit = () => {
    dispatch(submitBooking())
  }

  return (
    <ErrorBoundary>
      <header className={s.header}>
        <div className={s['title-wrapper']}>
          <Title tag={'h4'} sizes={'h3'} className={s.title}>
            {l10n('form_header_title_booking')}
          </Title>
          <span className={s['rest-name']}>{restaurantSource.name}</span>
        </div>

        <IconButton
          sizes={'L M'}
          mode={'white'}
          icon={IconClose}
          onClick={handleAbortBookingCallback}
          className={s['button-close']}
        />
      </header>

      <main className={s.main}>
        <BookingAlerts />

        <BookingGuests />

        {restaurantSource.schedule && <BookingDateTime />}

        <div className={s.fields}>
          <BookingFullName />
          <BookingPhone />
          <BookingComment />
        </div>

        <Text sizes={'S'} className={s.policy}>
          Нажимая кнопку &laquo;забронировать столик&raquo;, вы&nbsp;принимаете условия{' '}
          <a href={'https://www.afisha.ru/rests/usage/'} target='_blank' className={s['policy-link']}>
            Пользовательского соглашения{' '}
          </a>
          и{' '}
          <a href='https://www.afisha.ru/article/privacy-policy/' target='_blank' className={s['policy-link']}>
            Политики конфиденциальности
          </a>
        </Text>
      </main>

      <footer className={s.footer}>
        {isDesktop && (
          <span>
            {/* 2 guests */}
            {getGuestCountString(guestsCountField.value)}

            {/* 1 January */}
            {dateField.selected?.value && ', ' + dateField.selected.itemTextShort}

            {/* 12:00 */}
            {timeField.selected?.value && ', ' + timeField.selected.itemText}
          </span>
        )}

        <BookingButton
          className={s.submit}
          onClick={handleClickSubmit}
          disabled={onlineSlotsLoading || checkDiscountLoading}>
          {l10n('form_submit_button_text')}
        </BookingButton>
      </footer>
    </ErrorBoundary>
  )
}

export default BookingScreenForm
