import s from './DiscloseText.module.sass'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'

import React, { FC, memo, ReactNode, useEffect, useRef, useState } from 'react'

import { Button } from '@shared/ui/Actions/Button'
import { IconButton } from '@shared/ui/Actions/IconButton'

// @ts-ignore
import IconDots from '@icons/icon-dots.svg'

interface Props {
  maxHeight?: number
  children: ReactNode
  className?: string
  colorShadow?: string
  staticShadow?: boolean
  handleClick?: () => void
  isTextMode?: boolean // кнопка раскрытия в виде текста "читать полностью"
}

const DiscloseText: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  maxHeight = 150,
  children,
  colorShadow,
  staticShadow = false,
  handleClick,
  isTextMode = false,
  className = '',
  ...props
}) => {
  const dropdownTextWrapper = useRef()

  const [dropdown, setDropdown] = useState({
    color: colorShadow ? colorShadow : '#fff',
    height: maxHeight,
    isOpen: true,
  })

  useEffect(() => {
    setDropdown((prev) => ({
      ...prev,
      color: colorShadow,
      // @ts-ignore
      isOpen: dropdownTextWrapper?.current.children[0]?.offsetHeight < maxHeight,
    }))
  }, [dropdownTextWrapper.current, colorShadow, maxHeight])

  const openFullText = () => {
    if (staticShadow) {
      handleClick && handleClick()
      return
    }

    if (!dropdown.isOpen) {
      // @ts-ignore
      const heightFullText = dropdownTextWrapper?.current.children[0]?.offsetHeight
      setDropdown((prev) => ({
        ...prev,
        height: heightFullText,
        isOpen: true,
      }))
      handleClick && handleClick()
    }
  }

  return (
    <ErrorBoundary>
      <div className={className} {...props}>
        <div
          ref={dropdownTextWrapper}
          data-show={dropdown.isOpen}
          onClick={openFullText}
          style={{ maxHeight: `${dropdown.height}px` }}
          className={s['content']}
          data-no-margin={isTextMode}>
          <>
            <>{children}</>

            {!isTextMode && (
              <div
                className={s.shadow}
                style={{ background: `linear-gradient(180deg, transparent 0%, ${dropdown.color} 100%)` }}
              />
            )}
          </>
        </div>

        {!dropdown.isOpen && (
          <>
            {!isTextMode ? (
              <IconButton
                sizes='S'
                icon={IconDots}
                mode={'white-border'}
                onClick={openFullText}
                className={`${s.button} ${dropdown.isOpen ? s.open : ''} `}
              />
            ) : (
              <Button
                sizes='L'
                mode={'red-link'}
                onClick={openFullText}
                className={`${s.button} ${dropdown.isOpen ? s.open : ''} `}>
                Читать полностью
              </Button>
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default memo(DiscloseText)
