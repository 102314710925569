import React, { FC, HTMLProps } from 'react'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Button } from '@shared/ui/Actions/Button'
import { Title } from '@shared/ui/Typography/Title'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import s from './MediaCardCover.module.sass'

interface Props {
  title: string
  img: {
    desktop: string
    mobile: string
  }
  handleClick: () => void
  subtitles?: string[]
  buttonText?: string
  className?: string
}

const MediaCardCover: FC<Props & HTMLProps<HTMLDivElement>> = React.forwardRef(
  ({ title, img, handleClick, subtitles, buttonText, className = '', ...props }, ref) => {
    const { isMobile } = useClientResponsive()
    const imgLink = isMobile ? img.mobile : img.desktop

    // const placeholderImage = null
    // const thisImage = data?.cover?.cover1?.replace('//develop.rests', '//rests') || placeholderImage

    return (
      <ErrorBoundary>
        <section className={s.wrap} data-is-mobile={isMobile} {...props} ref={ref}>
          <aside className={s.aside} data-is-mobile={isMobile}>
            <div className={s['aside-content']} data-is-mobile={isMobile}>
              <Title
                className={s['aside-title']}
                tag='h3'
                sizes={'h2 h3 h4'}
                data-is-mobile={isMobile}
                onClick={handleClick}>
                {title}
              </Title>

              {subtitles?.length && (
                <div className={s['aside-description']} data-is-mobile={isMobile}>
                  {subtitles.map((text, index) => (
                    <div key={index}>
                      {index > 0 && <span className={s['aside-dot']}>·</span>}
                      <span className={s['aside-subtitle']}>{text}</span>
                    </div>
                  ))}
                </div>
              )}

              {buttonText && (
                <Button className={s['aside-open']} sizes='XL L M' mode={'white'} onClick={handleClick}>
                  {buttonText}
                </Button>
              )}
            </div>

            <div
              className={s['aside-background']}
              style={{ backgroundImage: `url(${imgLink})` }}
              data-is-mobile={isMobile}
            />
          </aside>

          {!isMobile && <main className={s.main} style={{ backgroundImage: `url(${imgLink})` }}></main>}
        </section>
      </ErrorBoundary>
    )
  },
)

export default MediaCardCover
