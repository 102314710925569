import s from './BookingGuests.module.sass'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import React, { ChangeEvent, FC } from 'react'
import { setGuestsCountValue } from '@widgets/Restaurant/Booking/model/reduce'
// @ts-ignore
import IconMinus from '@icons/icon-counter-minus.svg'
// @ts-ignore
import IconPlus from '@icons/icon-counter-plus.svg'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

const BookingGuests: FC = () => {
  const { restaurantSource, form } = useAppSelector((store) => store.booking)
  const { guestsCountField } = form
  const { isMobile } = useClientResponsive()
  const dispatch = useAppDispatch()

  const isGuestMinusDisabled = () => guestsCountField.value === guestsCountField.min
  const isGuestPlusDisabled = () => guestsCountField.value === guestsCountField.max

  const handleGuestCountMinus = () => {
    const setValue = parseInt(String(guestsCountField.value), 10) - 1
    dispatch(setGuestsCountValue(setValue))

    EGG.entity.booking.click_reserve_remove_guest()
  }
  const handleGuestCountPlus = () => {
    const setValue = parseInt(String(guestsCountField.value), 10) + 1
    dispatch(setGuestsCountValue(setValue))

    EGG.entity.booking.click_reserve_add_guest()
  }

  // Изменение поля "Количество гостей" если есть возможность редактирования
  const handleChangeGuestCount = (event: ChangeEvent<HTMLInputElement>) => {
    const thisGuests = event.target.value
    let filteredGuests = null
    if (parseInt(thisGuests, 10) < 1) {
      filteredGuests = 1
    } else if (parseInt(thisGuests, 10) > guestsCountField.max) {
      filteredGuests = guestsCountField.max
    } else {
      filteredGuests = thisGuests
    }
    dispatch(setGuestsCountValue(Number(filteredGuests)))
  }

  return (
    <ErrorBoundary>
      <div
        className={`${s['booking-row-child']} ${s['booking-row-child--in-group']} ${s['booking-row-child--space-between']}`}>
        <label htmlFor='select_booking-guest-count' className={s['booking-label']}>
          {isMobile ? l10n('guest_count_label') : l10n('guest_count_label')}
        </label>

        <div className={s['booking-form-elem-wrap']}>
          <div className={s['booking-guest-count-wrap']}>
            <button
              className={
                !isGuestMinusDisabled()
                  ? s['booking-guest-count-minus']
                  : `${s['booking-guest-count-minus']} ${s['booking-guest-count-minus--disabled']}`
              }
              disabled={isGuestMinusDisabled()}
              onClick={handleGuestCountMinus}
              type='button'>
              <IconMinus />
            </button>

            <input
              className={s['booking-guest-count-input']}
              name='booking-guest-count-input'
              id='select_booking-guest-count'
              value={guestsCountField.value}
              onChange={handleChangeGuestCount}
              type='number'
              min={guestsCountField.min}
              max={guestsCountField.max}
              readOnly
            />

            <button
              className={
                !isGuestPlusDisabled()
                  ? s['booking-guest-count-plus']
                  : `${s['booking-guest-count-plus']} ${s['booking-guest-count-plus--disabled']}`
              }
              disabled={isGuestPlusDisabled()}
              onClick={handleGuestCountPlus}
              type='button'>
              <IconPlus />
            </button>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default BookingGuests
