import { sendClickHouseAnalytic } from '@shared/api/analytics/template'
import { PickAnalytic } from '@shared/api/analytics/types/types'

// Уникальные события для страницы ресторана
export const EGGRestItem = {
  // 1. Отобразился экран
  appear_screen_page: () =>
    sendClickHouseAnalytic({
      event_name: 'screen_appear',
    }),

  button_to_reviews_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'to_reviews',
    }),

  description_view: (props: PickAnalytic<'description'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_view', // basic
      element_type: 'description',
      ...props,
    }),

  schedule_click_open: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'show_schedule',
    }),

  schedule_click_close: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'hide_schedule',
    }),

  gallery_video_play_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'trailer_watch',
    }),

  gallery_photo_view: (props: PickAnalytic<'section_index', 'section_name'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'picture',
      ...props,
    }),

  map_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'map',
    }),

  menu_item_view: (props: PickAnalytic<'menu_type' | 'object_type' | 'object_id' | 'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'menu_item',
      ...props,
    }),

  menu_item_click: (props: PickAnalytic<'menu_type' | 'object_type' | 'object_id' | 'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'menu_item',
      ...props,
    }),

  special_offer_click: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'special_news',
    }),

  new_menu_item_click: (props: PickAnalytic<'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'new_menu_item',
      ...props,
    }),

  qa_click: (props: PickAnalytic<'section_index'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click', // basic
      element_type: 'q&a',
      section_index: props.section_index,
      ...props,
    }),

  event_card_view: (props: PickAnalytic<'section_index' | 'object_id'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_view',
      element_type: 'creation_card',
      section_name: 'afisha_events',
      object_type: 'event',
      section_index: props.section_index,
      object_id: props.object_id,
    }),

  event_card_click: (props: PickAnalytic<'section_index' | 'object_id'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'creation_card',
      section_name: 'afisha_events',
      object_type: 'event',
      section_index: props.section_index,
      object_id: props.object_id,
    }),

  event_price_click: (props: PickAnalytic<'section_index' | 'object_id'>) =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'ticket',
      section_name: 'afisha_events',
      object_type: 'event',
      section_index: props.section_index,
      object_id: props.object_id,
    }),
}
