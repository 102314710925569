import s from './SelectionMainCard.module.sass'
import React, { FC, useRef } from 'react'

import { Title } from '@shared/ui/Typography/Title'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MainCard } from '@shared/ui/Layout/Cards/MainCard'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { useAppSelector } from '@app/model/store'
import { Rating } from '@shared/ui/Feedback/Rating'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'
import { PickAnalytic } from '@shared/api/analytics/types/types'

interface Props {
  data: TypeMappingSelectionOutput
  analytic: PickAnalytic<'section_index' | 'section_name'>
}

const SelectionMainCard: FC<Props> = ({ data, analytic }) => {
  const globalState = useAppSelector((store) => store.global)
  const { img, url, description, categories } = data
  const thisCoverImage = img.sizes.desktop.vertical
  const href = `/${globalState.currentCity.slug}/restaurants/${url.slug}`
  const alt = `Фото подборки: ${description.title}`
  const cardRef = useRef()
  const handleClick = () => EGG.entity.selection.card_click(data, analytic)
  useIntersectionAnalytic(cardRef, () => EGG.entity.selection.card_view(data, analytic))

  return (
    <ErrorBoundary>
      <MainCard
        img={thisCoverImage}
        href={href}
        alt={alt}
        classNameContent={s.card}
        chevron={categories.selection}
        onClick={handleClick}
        ref={cardRef}>
        {categories.advert.active && <Rating isPromo={categories.advert.active} className={s.promo} />}
        <Title tag='h2' sizes='h2 h3 h5' className={s.title}>
          {description.title}
        </Title>
      </MainCard>
    </ErrorBoundary>
  )
}

export default SelectionMainCard
