import React, { FC, memo } from 'react'
import s from './Select.module.sass'
import { getOptions, getSelectLabel, isClassPlaceholder } from './helpers'
import useOutsideClick from '@shared/lib/hooks/useOutsideClick'
import { FieldSelect, SelectOption } from '@shared/types/fields/fields'
import { getErrorData } from '@shared/lib/fields/getErrorData'
// @ts-ignore
import IconArrow from '@icons/icon-arrow-small.svg'
import { ErrorBoundary } from '../../../../lib/components/ErrorBoundary'

interface Props {
  field: FieldSelect
  // eslint-disable-next-line
  handleChange: (option: SelectOption) => void
}

const Select: FC<Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  field,
  handleChange,
  ...props
}) => {
  const option = getOptions(field)
  const errorData = getErrorData(field)

  const { ref, isVisible: isDropDown, setIsVisible: setDropDown } = useOutsideClick(false)
  const onClickSelect = () => !field.disabled && setDropDown(!isDropDown)
  const handleClick = (option: SelectOption) => {
    if (!option.disabled) {
      const currentValue = field.value

      setDropDown(false)
      if (option.value !== currentValue?.value) {
        handleChange(option)
      }
    }
  }

  return (
    <ErrorBoundary>
      <div ref={ref} id={field.name} className={s.wrapper} {...props}>
        <div className={`${s.field} ${errorData.class}`} onClick={onClickSelect} data-disabled={field.disabled}>
          <span className={`${s.field__value} ${s[isClassPlaceholder(field) ? 'field__value--placeholder' : '']}`}>
            {getSelectLabel(field)}
          </span>
          <button type='button' className={s.field__arrow} data-close={isDropDown}>
            <IconArrow />
          </button>
        </div>
        {option.length && (
          <div className={s.dropdown} data-visible={isDropDown}>
            <div className={s.dropdown__shadow}></div>
            <ul className={s.dropdown__list} role='menu' aria-labelledby='dropdownMenu'>
              {option.map((el: SelectOption) => (
                <li
                  key={el.value}
                  className={s.dropdown__item}
                  role='presentation'
                  data-disabled={el.disabled}
                  onClick={() => handleClick(el)}>
                  <span className={s.dropdown__link} role='menuitem'>
                    {el.label}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default memo(Select)
