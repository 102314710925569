import React, { FC, HTMLProps } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import s from './MediaCardCompilation.module.sass'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { Rating } from '@shared/ui/Feedback/Rating'
import { Title } from '@shared/ui/Typography/Title'
import { useCurrentSize } from '@shared/lib/helpers/classes'

// @ts-ignore
import IconLike from '@icons/icon-like.svg'
// @ts-ignore
import IconSelectionChevronBig from '@icons/icon-selection-chevron--big.svg'
// @ts-ignore
import IconSelectionChevronMedium from '@icons/icon-selection-chevron--medium.svg'

// D-desktop - M-mobile
// type size = 'D1x1 D2x1 M1x1'
interface Props {
  id: string | number
  title: string
  sizes: string
  img: {
    horizontal: string
    vertical: string
  }
  promo?: boolean
  chevron?: boolean
  link: string
  className?: string
}

const MediaCardCompilation: FC<Props & HTMLProps<HTMLDivElement>> = React.forwardRef(
  ({ id, sizes, className = '', title, img, promo = false, chevron = false, link, ...props }, ref) => {
    const [size] = useCurrentSize(sizes)

    const firstNumbers = parseInt(title)
    const firstNumbersIsTrue = !!firstNumbers && firstNumbers > 2
    // @ts-ignore
    const titleFormatted = firstNumbersIsTrue ? title.replace(firstNumbers, '') : title

    return (
      <ErrorBoundary>
        <div data-id={id} className={`${s.card} ${className}`} data-size={size} {...props} ref={ref}>
          <Link href={link} scroll={false}>
            {promo && <Rating id={s.promo} className={s.promo} mode={'fill'} isPromo sizes={'S'} />}

            {chevron && size === 'M1x1' && (
              <IconSelectionChevronMedium
                className={s['afisha-selection-chevron--medium']}
                width={'29px'}
                height={'50px'}
                alt={'Основной Выбор Афиши'}
              />
            )}
            {chevron && size !== 'M1x1' && (
              <IconSelectionChevronBig
                className={s['afisha-selection-chevron--big']}
                width={'36px'}
                height={'64px'}
                alt={'Основной Выбор Афиши'}
              />
            )}

            {!!firstNumbersIsTrue && <span className={s.numbers}>{firstNumbers}</span>}

            <Title tag={'h5'} sizes={'h4'} className={s.title}>
              {titleFormatted}
            </Title>

            <div className={s['image-wrapper']}>
              <div className={s['image-inner']}>
                <Image
                  layout={'fill'}
                  src={
                    size === 'D2x1' ? img.horizontal || getPlaceholderImage() : img.vertical || getPlaceholderImage()
                  }
                  alt='card-main'
                  className={s.image}
                />
              </div>
            </div>
          </Link>
        </div>
      </ErrorBoundary>
    )
  },
)

export default MediaCardCompilation
