// First, create the thunk
import { TypeDay } from '@widgets/Restaurant/Booking/types'
import {
  openModal,
  setCheckDiscountLoading,
  setDatesOptions,
  setDefaultDay,
  setDefaultTime,
  setDisabledAlertItem,
  setIsErrorsVisible,
  setIsOpenScreenSuccess,
  setNameFieldValue,
  setPhoneFieldValue,
  setSchedule,
  setSelectedDate,
  setSelectedTime,
  setTimesOptions,
} from '@widgets/Restaurant/Booking/model/reduce'
import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit'
import { TypeRestaurant } from '@widgets/Restaurant/Booking/model/types'
import { Dispatch } from 'react'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { EGG } from '@shared/api/analytics'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { isPhonePatternValid } from '@widgets/Restaurant/Booking/lib/helper'

export const submitBooking = createAsyncThunk('booking/submitBooking', async (action, { dispatch, getState }) => {
  // check valid form

  // @ts-ignore
  const { form, restaurantSource } = getState().booking
  const { guestsCountField, nameField, phoneField, commentField, dateField, timeField } = form
  const arrayValidFields = [
    guestsCountField.valid,
    nameField.valid,
    phoneField.valid,
    commentField.valid,
    dateField.valid,
    timeField.valid,
  ]

  const isValidAllFields = arrayValidFields.every((fieldValid) => fieldValid)
  if (!isValidAllFields) {
    // @ts-ignore
    dispatch(setIsErrorsVisible())
    return
  }

  EGG.entity.booking.click_reserve_book({
    count_guest: String(guestsCountField.value),
    phone: phoneField.value,
    date: String(dateField.selected.value),
    time: String(timeField.selected.value),
  })

  const date = dateField.selected.value
  const time = timeField.selected.value

  API.booking_create({
    body: {
      restaurant: Number(restaurantSource.id),
      guest_phone: phoneField.value,
      num_of_places: Number(guestsCountField.value),
      reservation_at: `${date} ${time}`,
      guest_name: nameField.value,
      comment: commentField.value,
    },
    // repeated: true,
  })
    .then(() => {
      dispatch(setIsOpenScreenSuccess(true))
      window.localStorage.setItem('booking-guest-name', JSON.stringify(nameField.value))
      window.localStorage.setItem('booking-guest-phone', JSON.stringify(phoneField.value))
    })
    .catch((error) => {
      const noticeText = error?.message || 'Ошибка отправки формы бронирования'
      // @ts-ignore
      dispatch(addTemporaryNotice(noticeText))
    })
})

export const handleSubmitForm = () => (dispatch: Dispatch<AnyAction>) => {
  // @ts-ignore
  dispatch(setIsErrorsVisible())
}

export const selectDate = (dateObj: TypeDay) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setSelectedDate(dateObj))
  dispatch(setTimesOptions(dateObj))
}

export const checkDiscountByNumber = createAsyncThunk(
  'booking/checkDiscountByNumber',
  async (action: { phone: string; restId: number }, { dispatch, getState }) => {
    // @ts-ignore
    const stateDiscounts = getState().booking.restaurantSource.discounts
    const hasDiscounts = !!stateDiscounts.tastyclub || !!stateDiscounts.remarked

    if (!hasDiscounts) return
    if (!isPhonePatternValid(action.phone)) return

    const numbers = parseInt(action.phone.replace(/[^\d]/g, ''))

    dispatch(setCheckDiscountLoading(true))
    dispatch(setDisabledAlertItem({ type: 'discount', disabled: true }))

    await API.booking_discount({ body: { phone: numbers, rest_id: action.restId } })
      .then((res) => {
        dispatch(setDisabledAlertItem({ type: 'discount', disabled: !res.discount }))
      })
      .finally(() => {
        dispatch(setCheckDiscountLoading(false))
      })
  },
)

export const openBooking = (restaurantData: TypeMappingRestOutput) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setSchedule(restaurantData.schedule.week))
  // @ts-ignore
  dispatch(setDatesOptions())
  // @ts-ignore
  dispatch(setDefaultDay())
  // @ts-ignore
  dispatch(setDefaultTime())
  // @ts-ignore
  dispatch(setTimesOptions())
  // @ts-ignore
  dispatch(setSelectedDate())
  // @ts-ignore
  dispatch(setSelectedTime())
  // @ts-ignore
  dispatch(setFieldsValuesFromLocalStorage())

  dispatch(
    openModal({
      restaurant: restaurantData,
    }),
  )
}

export const setFieldsValuesFromLocalStorage = () => (dispatch: Dispatch<AnyAction>) => {
  const isWindowAvailable = typeof window !== 'undefined'
  if (isWindowAvailable) {
    const storedName = window.localStorage.getItem('booking-guest-name')
    const storedPhone = window.localStorage.getItem('booking-guest-phone')

    storedName && dispatch(setNameFieldValue(JSON.parse(storedName)))
    storedPhone && dispatch(setPhoneFieldValue(JSON.parse(storedPhone)))
  }
}
